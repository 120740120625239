<template>
  <div class="white shadow rounded pa-3 pb-5 ticket elevation-5" @click="showTicket(ticket)">
    <div class="d-flex justify-between">
      <div class="body-1 text--black">{{ ticket.title }}</div>
      <v-avatar size="30">
        <v-img src="https://pickaface.net/gallery/avatar/unr_sample_161118_2054_ynlrg.png" />
      </v-avatar>
    </div>
    <div class="d-flex mt-4 justify-between items-center">
      <div class="text-sm grey--text">{{ ticket.created_at }}</div>
      <div>{{ ticket.type }}</div>
    </div>
  </div>
</template>
<script>
import TicketsMixin from "./TicketsMixin";

export default {
  mixins: [TicketsMixin],
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    badgeColor() {
      const mappings = {
        Design: "purple",
        "Feature Request": "teal",
        Backend: "blue",
        QA: "green",
        default: "teal",
      };
      return mappings[this.task.type] || mappings.default;
    },
  },
};
</script>

<style scoped>
.ticket {
  border: solid 1px white;
}
</style>
