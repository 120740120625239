<template>
  <v-app id="app">
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      tickInterval: null,
    };
  },
  computed: {
    layout() {
      return (this.$route.meta.layout || "cabinet") + "-layout";
    },
  },
  created() {
    this.tickInterval = setInterval(() => this.$store.commit("tick"), 5000);
  },
  destroyed() {
    if (this.tickInterval) {
      clearInterval(this.tickInterval);
    }
  },
};
</script>

<style>
.required label::after {
  content: "*";
  color: red;
  margin-left: 3px;
}
</style>
