<template>
  <div>
    <v-card flat>
      <v-card-title>
        {{ $t("triggers.triggers") }}
        <v-spacer />
        <div class="ml-10">
          <v-btn small color="success" @click="add"><v-icon small>mdi-plus</v-icon>{{ $t("add") }}</v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" class="elevation-1">
          <template #item.actions="{ item }">
            <v-btn small color="primary" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="800px">
      <Trigger :channels="channels" :trigger="item" @close="closeEditDialog" />
    </v-dialog>
  </div>
</template>

<script>
import BaseView from "../BaseView.vue";
import Trigger from "./TriggerEdit.vue";

const baseURL = "/triggers";

export default {
  name: "TriggersList",
  components: { Trigger },
  mixins: [BaseView],
  data() {
    return {
      perm: "triggers",
      channels: [],
      editDialog: false,
      headers: [
        { text: this.$t("name"), value: "hosts", width: "20%", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      items: [],
      item: {},
      loading: false,
    };
  },
  async created() {
    this.setTitle(this.$t("triggers.triggers"));
    this.checkPermission();
    await this.loadInfo();
    await this.refresh();
  },
  methods: {
    add() {
      this.item = {};
      this.editDialog = true;
    },
    edit(item) {
      this.item = item;
      this.editDialog = true;
    },
    async closeEditDialog() {
      this.editDialog = false;
      await this.refresh();
    },
    async loadInfo() {
      try {
        const resp = await this.$api.get("channels");
        this.channels = resp.data.results;
      } catch (error) {
        console.error("Error load channels list: ", error);
      }
    },
    async refresh() {
      this.loading = true;

      try {
        const resp = await this.$api.get(baseURL);
        this.items = resp.data.results;
      } catch (error) {
        console.error("Error load triggers list: ", error);
      }

      this.loading = false;
    },
  },
};
</script>
