<template>
  <div class="d-flex flex-column height-100">
    <div class="header d-flex align-center pa-2">
      <v-btn v-if="mobile" icon @click="$emit('clearThread')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-avatar size="40" class="mr-3">
        <v-img :src="getAvatar(thread)" width="40" />
      </v-avatar>
      <div>
        <h4>{{ thread.name }}</h4>
        <div>
          <v-chip v-if="thread.type" x-small color="blue lighten-3 white--text mr-2">
            {{ $t("chats.type-" + thread.type) }}
          </v-chip>
          <v-chip v-if="thread.group" x-small color="blue lighten-3 white--text mr-2">{{ thread.group.name }}</v-chip>
          <v-chip v-if="thread.user" x-small color="green lighten-3 white--text">{{ thread.user.name }}</v-chip>
        </div>
      </div>
      <v-spacer />
      <v-btn outlined small color="error" class="mr-2" @click="setUser(user.id)" v-if="thread.user_id !== user.id">
        {{ $t("chats.claim") }}
      </v-btn>
      <v-btn
        outlined
        small
        color="success"
        class="mr-2"
        @click="updateThread(thread, { resolved: true })"
        v-if="thread.user_id === user.id && !thread.resolved"
      >
        {{ $t("chats.resolve") }}
      </v-btn>
      <v-btn
        outlined
        small
        color="primary"
        class="mr-2"
        @click="showTransferDialog = true"
        v-if="thread.user_id === user.id && !thread.resolved"
      >
        {{ $t("chats.transfer") }}
      </v-btn>
      <v-btn icon @click="showChatInfo">
        <v-icon color="info">mdi-information-outline</v-icon>
      </v-btn>
    </div>
    <div ref="chatContainer" class="messages pa-2 pb-1">
      <Message v-for="message in threadMessages" :key="message.id" :message="message" :thread="thread" />
      <div v-if="threadMessages && threadMessages.length === 0" class="d-flex justify-center align-center">
        <h4>{{ $t("chats.no-messages") }}</h4>
      </div>
      <div class="text-center caption">
        <span v-if="thread.typing">{{ $t("chats.user-typing") }}</span>
        <span v-else>&nbsp;</span>
      </div>
    </div>
    <div class="pa-1 new-message d-flex align-center">
      <v-menu top right offset-y origin="bottom right" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn icon class="px-1 mr-1" v-on="on">
            <v-icon color="blue">mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="showTemplatesDialog = true">
            <v-list-item-title>{{ $t("chats.add-template-message") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-textarea
        v-model="text"
        :disabled="thread.status === 'b' || loading"
        dense
        outlined
        hide-details
        clearable
        auto-grow
        rows="2"
        class="body-2"
        @blur="updateThreadText(thread, text)"
        @keyup="inputHandler"
      />
      <v-btn icon class="ml-1 px-1" :disabled="text.trim().length === 0" @click="sendMessage">
        <v-icon color="blue">mdi-send</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="showInfoDialog" persistent max-width="600px" :fullscreen="mobile">
      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>{{ thread.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="showInfoDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <ChatInfo :thread="thread" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTemplatesDialog" persistent max-width="600px">
      <TemplatesDialog :thread="thread" @close="showTemplatesDialog = false" @text="addText" />
    </v-dialog>
    <v-dialog v-model="showTransferDialog" persistent max-width="400px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("chats.transfer") }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="showTransferDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
          <v-select
            dense
            outlined
            hide-details
            append-icon="mdi-menu-down"
            item-value="id"
            item-text="name"
            :items="users"
            v-model="transferUserId"
            :label="$t('chats.user')"
          >
            <template v-slot:item="{ item }">
              <span class="body-2 mr-2 font-weight-bold">
                {{ item.name }}
              </span>
              <span class="caption">{{ user.position }}</span>
            </template>
          </v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small color="success" @click="transferChat(transferUserId)">
            {{ $t("chats.transfer") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import BaseView from "@/components/BaseView";
import ChatInfo from "./ChatInfo";
import Message from "./Message";
import TemplatesDialog from "./TemplatesDialog";
import ChatMixin from "./ChatMixin";

export default {
  name: "MessagesContainer",
  components: { ChatInfo, Message, TemplatesDialog },
  async created() {
    if (this.thread) {
      this.text = this.thread.text;
      await this.$ws.sendEvent("chat.messages", { thread_id: this.thread.id });
    }
  },
  computed: {
    ...mapState(["mobile", "user", "users"]),
    threadMessages() {
      const thread = this.$store.getters.getThread(this.thread.id);
      this.scrollTo();
      return thread ? thread.messages : [];
    },
  },
  mixins: [BaseView, ChatMixin],
  props: {
    statuses: Object,
    thread: Object,
  },
  data() {
    return {
      loading: false,
      text: "",
      showInfoDialog: false,
      showTemplatesDialog: false,
      transferUserId: null,
      showTransferDialog: false,
    };
  },
  methods: {
    addText(text) {
      this.text = text + (this.text.length > 0 ? " " + this.text : "");
    },
    async inputHandler(e) {
      if (e.keyCode === 13 && !e.shiftKey) {
        e.preventDefault();
        await this.sendMessage();
      }
    },
    scrollTo() {
      this.$nextTick(() => {
        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
      });
    },
    showChatInfo() {
      if (this.mobile) {
        this.showInfoDialog = true;
      } else {
        this.$emit("toggleChatInfo");
      }
    },
    async sendMessage() {
      const msg = {
        thread_id: this.thread.id,
        text: this.text.trim(),
        type: "t",
        user_id: this.user.id,
      };

      this.loading = true;
      try {
        await this.$ws.sendEvent("chat.message", msg);
        // const resp = await this.$api.post("threads/" + this.thread.id + "/messages", msg);
        //
        // const newMessage = resp.data;
        // newMessage.thread_id = this.thread.id;
        // this.$store.commit("updateThreadMessage", newMessage);
        //
        // // update thread immediately
        // const thread = { ...this.thread };
        // thread.last_message_text = msg.text;
        // thread.last_message_dt = newMessage.created;
        // this.$store.commit("updateThread", thread);

        this.text = "";
        this.updateThreadText(this.thread, this.text);
      } catch (err) {
        console.error("Error add a new message", err);
      }

      this.loading = false;
    },
    async transferChat(userId) {
      await this.setUser(userId);
      this.showTransferDialog = false;
    },
  },
  watch: {
    async thread() {
      if (this.thread) {
        this.text = this.thread.text;
        await this.$ws.sendEvent("chat.messages", { thread_id: this.thread.id });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  flex-shrink: 1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.messages {
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100vh - 300px);
}
.new-message {
  flex-shrink: 1;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
</style>
