<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("kb.knowledge-base") }} &laquo;{{ kb.name }}&raquo;
        <v-spacer />
        <v-btn color="info" small class="mr-3" @click="editCategory({})">
          <v-icon small>mdi-plus</v-icon> {{ $t("kb.add-category") }}
        </v-btn>
        <v-btn color="success" :to="{ name: 'kb-page-edit', params: { id: 'new', kb: kb.id } }" small>
          <v-icon small>mdi-plus</v-icon> {{ $t("kb.add-page") }}
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-1">
        <v-treeview activatable dense hoverable open-on-click :items="items" transition :load-children="loadPages">
          <template #label="{ item }">
            <div @click="openCategory(item)" @dblclick="editCategory(item)">{{ item.name }} (/{{ item.slug }})</div>
          </template>
          <template #prepend="{ item, open }">
            <v-icon v-if="item.category" color="amber lighten-1">
              {{ open ? "mdi-folder-open" : "mdi-folder" }}
            </v-icon>
            <v-icon v-else color="blue">mdi-file-document-outline</v-icon>
          </template>
        </v-treeview>
      </v-card-text>
    </v-card>
    <v-dialog v-model="categoryDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("kb.category") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="categoryDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="item.name" outlined dense hide-details :label="$t('name')" />
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="item.slug" outlined dense hide-details :label="$t('kb.slug')" />
            </v-col>
            <v-col cols="4">
              <v-text-field v-model="item.order_num" outlined dense hide-details :label="$t('order-num')" />
            </v-col>
            <v-col cols="8">
              <v-textarea
                v-model="item.description"
                auto-grow
                rows="3"
                outlined
                dense
                hide-details
                :label="$t('description')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="saveCategory">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "@/components/BaseView";
import KnowledgeBaseMixin from "./KnowledgeBaseMixin.vue";

export default {
  name: "KnowledgeBase",
  components: {},
  mixins: [BaseView, KnowledgeBaseMixin],
  data() {
    return {
      perm: "kb",
      items: [],
      item: {},
      categoryDialog: false,
    };
  },
  computed: {
    kb() {
      for (const kb of this.$store.state.knowledgeBases) {
        if (kb.id === this.$route.params.kb) {
          return kb;
        }
      }
      return null;
    },
    kbUrl() {
      return "kb/" + this.$route.params.kb;
    },
  },
  async created() {
    this.setTitle(this.$t("kb.knowledge-base"));

    this.items = await this.loadCategories(this.$route.params.kb);
  },
  methods: {
    editCategory(item) {
      this.item = item;
      this.categoryDialog = true;
    },
    async loadPages(item) {
      try {
        const resp = await this.$api.get(this.kbUrl + "/pages", { category: item.id });
        item.children = resp.data.pages.map((p) => {
          p.category = false;
          return p;
        });
      } catch (e) {
        console.error("Error load KB pages", e);
      }
    },
    openCategory(item) {
      if (!item.category) {
        this.$router.push({ name: "kb-page-edit", params: { kb: this.kb.id, id: item.id } });
      }
    },
    async saveCategory() {
      const item = { ...this.item };
      item.knowledge_base = this.kb.id;

      const resp = await this.$api.save(this.kbUrl + "/categories", item);
      if (resp.success) {
        this.showSnackbar("success", this.$t("saved"));
        this.categoryDialog = false;
        this.item = {};
        this.items = await this.loadCategories(this.$route.params.kb);
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
  },
};
</script>

<style scoped>
.v-card__title {
  border-bottom: solid 1px rgb(0, 0, 0, 0.12);
}
</style>
