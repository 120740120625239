<template>
  <v-container fluid class="fill-height form pa-0">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="4" class="pa-6">
        <v-img src="@/assets/images/logo.svg" width="120" class="mb-5" />
        <div class="title mb-4">{{ $t("auth.welcome") }}</div>
        <v-form lazy-validation ref="form">
          <v-card class="elevation-6 mb-6 pa-5">
            <v-card-title>
              <v-spacer />
              {{ $t("auth.new-account") }}
              <v-spacer />
            </v-card-title>
            <v-card-text>
              <v-alert v-if="error" type="error">{{ $t("auth.error") }}</v-alert>
              <v-text-field
                outlined
                dense
                class="required"
                v-model="form.name"
                :label="$t('auth.name')"
                :rules="[requiredValidator]"
              />
              <v-text-field
                outlined
                dense
                type="email"
                class="required"
                v-model="form.email"
                :label="$t('auth.email')"
                :rules="[requiredValidator, emailValidator]"
              />
              <v-text-field outlined dense v-model="form.phone" :label="$t('auth.phone')" type="phone" />
              <v-text-field
                outlined
                dense
                class="required"
                v-model="form.password"
                :label="$t('auth.password')"
                type="password"
                :rules="[requiredValidator]"
              />
              <v-text-field
                outlined
                dense
                class="required"
                v-model="form.company"
                :label="$t('auth.company')"
                @blur="generateCode"
                :rules="[requiredValidator]"
              />
              <v-text-field outlined dense v-model="form.code" :label="$t('auth.code')" :messages="subdomain" />
              <v-select
                outlined
                dense
                class="required"
                v-model="form.lang"
                :label="$t('auth.lang')"
                :items="languages"
                item-value="id"
                item-text="name"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="success" @click="signUp">{{ $t("auth.register") }}</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-form>
        <div class="subtitle mb-6">
          {{ $t("auth.has-account") }}? <router-link to="login">{{ $t("auth.auth") }}</router-link>
        </div>
      </v-col>
      <v-col cols="12" sm="8" class="pa-0 d-none d-md-block">
        <div class="text-center">
          <img src="https://supportix.co/images/choose-channel.svg" width="500" alt="" />
        </div>
        <div class="title mb-2 mt-6">{{ $t("auth.support") }}</div>
        <div class="subtitle my-2">
          {{ $t("auth.email") }}: <a href="mailto:info@supportix.co">info@supportix.co</a>
        </div>
        <div class="subtitle my-2">
          {{ $t("auth.telegram") }}: <a href="https://t.me/OurSupportixBot">@OurSupportixBot</a>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import getSlug from "speakingurl";
import BaseView from "@/components/BaseView";

export default {
  name: "AccountRegister",
  mixins: [BaseView],
  computed: {
    ...mapState(["mobile"]),
    subdomain() {
      return this.form.code ? this.form.code + ".supportix.co" : "";
    },
  },
  created() {
    this.setTitle(this.$t("auth.register"));
  },
  data() {
    return {
      error: false,
      form: {
        name: "",
        email: "",
        phone: "",
        password: "",
        company: "",
        code: "",
        lang: "uk",
      },
    };
  },
  methods: {
    generateCode() {
      this.form.code = getSlug(this.form.company);
    },
    async signUp() {
      if (this.form.code.length === 0) {
        this.generateCode();
      }
      if (!this.$refs.form.validate()) return;
      this.error = false;

      const data = { ...this.form };
      data.langs = [data.lang];

      try {
        const resp = await this.$api.post("account/sign-up", data);
        if (resp.success) {
          this.$router.push({ name: "login" });
        } else {
          this.error = true;
        }
      } catch (err) {
        console.error("Error registration", err);
      }
    },
  },
};
</script>

<style scoped>
.form {
  background-color: #f5f5ff;
}
.img {
  height: 100vh;
}
</style>
