<template>
  <v-card>
    <v-toolbar color="primary" dark dense flat>
      <v-toolbar-title>{{ $t("clients.client") }} {{ item.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-row>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.first_name"
            outlined
            dense
            hide-details="auto"
            :label="$t('clients.first-name')"
            required
            :error-messages="errors.first_name"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.last_name"
            outlined
            dense
            hide-details="auto"
            :label="$t('clients.last-name')"
            :error-messages="errors.last_name"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.phone"
            outlined
            dense
            hide-details="auto"
            type="phone"
            :label="$t('clients.phone')"
            :error-messages="errors.phone"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-text-field
            v-model="item.email"
            outlined
            dense
            hide-details="auto"
            type="email"
            :label="$t('clients.email')"
            :error-messages="errors.phone"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-select
            v-model="item.group_id"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="id"
            :items="groups"
            :label="$t('clients.group')"
          />
        </v-col>
        <v-col cols="12" sm="6">
          <v-textarea v-model="item.comment" outlined dense hide-details="auto" :label="$t('clients.comment')" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn small color="success" @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseView from "@/components/BaseView.vue";

export default {
  name: "ClientForm",
  mixins: [BaseView],
  props: {
    client: {
      type: Object,
      default: null,
    },
    first_name: {
      type: String,
      default: "",
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    groups: Array,
  },
  data() {
    return {
      clientGroups: [],
      errors: {},
      item: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        group_id: "",
      },
    };
  },
  async mounted() {
    if (this.client) {
      this.item = this.client;
    }
    if (this.first_name.length > 0) {
      this.item.first_name = this.first_name;
    }

    // load client groups
    if (this.clientGroups.length === 0) {
      try {
        const resp = await this.$api.get("clients/groups");
        if (resp.success) {
          this.clientGroups = resp.data.groups;
        }
      } catch (err) {
        console.error("Error load client groups", err);
      }
    }
  },
  methods: {
    async save() {
      const item = { ...this.item };
      delete item.avatar;

      const resp = await this.$api.save("clients", item);
      if (resp.success) {
        this.$emit("close", resp.data);
      } else {
        this.errors = this.processAPIErrors(resp.error.data);
      }
    },
  },
  watch: {
    groups() {
      this.clientGroups = this.groups;
    },
  },
};
</script>
