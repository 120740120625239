<template>
  <v-container>
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("account.profile") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.first_name" outlined dense hide-details :label="$t('clients.first-name')" />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="item.last_name"
              outlined
              dense
              hide-details
              :label="$t('clients.last-name')"
              readonly
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.phone" outlined dense hide-details :label="$t('account.phone')" />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.position" outlined dense hide-details :label="$t('account.position')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="item.langs"
              multiple
              outlined
              dense
              hide-details
              :label="$t('account.languages')"
              :items="langs"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-row>
              <v-col cols="4">
                <v-img v-if="item.avatar" :src="item.avatar" max-height="100" contain />
              </v-col>
              <v-col cols="8">
                <v-file-input v-model="avatar" outlined dense hide-details :label="$t('avatar')" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" small @click="save">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import BaseView from "../BaseView.vue";

const baseURL = "/account/profile";

export default {
  name: "SettingsProfile",
  mixins: [BaseView],
  data() {
    return {
      item: {},
      avatar: null,
      langs: [
        { id: "ru", name: "Русский" },
        { id: "en", name: "English" },
        { id: "uk", name: "Українська" },
        { id: "de", name: "German" },
      ],
    };
  },
  async created() {
    this.setTitle(this.$t("account.profile"));
    this.checkPermission();

    const resp = await this.$api.get(baseURL);
    if (resp.success) {
      this.item = resp.data;
    }
  },
  methods: {
    async save() {
      let item = { ...this.item };
      delete item.avatar;

      const resp = await this.$api.patch(baseURL, item);
      if (resp.success) {
        this.item = resp.data;
        this.showSnackbar("success", this.$t("saved"));
        await this.uploadAvatar();
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
    async uploadAvatar() {
      if (!this.avatar) return;

      const data = new FormData(),
        that = this;
      data.append("avatar", this.avatar);

      const resp = await this.$api.patch(baseURL, data);
      if (resp.success) {
        that.item = resp.data;
      }
    },
  },
};
</script>
