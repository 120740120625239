<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-toolbar-title>{{ $t("tickets.board") }} {{ board.name }}</v-toolbar-title>
        <v-spacer />
        <v-btn color="success" small @click="addTicket(board)"><v-icon>mdi-plus</v-icon>{{ $t("add") }}</v-btn>
      </v-toolbar>
      <v-card-text class="pa-4">
        <div class="d-flex overflow-y-auto">
          <div v-for="column in columns" :key="column.id" class="grey lighten-4 rounded column-width mr-4 pb-4">
            <div class="rounded body-1 pa-2 grey lighten-1">{{ column.name }}</div>
            <div class="pa-2 pt-0">
              <draggable
                v-model="column.tasks"
                :animation="200"
                ghost-class="ghost-card"
                group="tickets"
                :move="onMove"
              >
                <TicketCard v-for="ticket in column.tickets" :key="ticket.id" :ticket="ticket" class="mt-3" />
              </draggable>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import draggable from "vuedraggable";
import TicketCard from "./TicketCard";
import TicketsMixin from "./TicketsMixin";

export default {
  name: "TicketsColumns",
  components: {
    TicketCard,
    draggable,
  },
  mixins: [TicketsMixin],
  async created() {
    await this.refresh();
    this.buildBoard();
  },
  data() {
    return {
      columns: [],
      filter: {},
    };
  },
  methods: {
    onMove(e) {
      console.log(e);
    },
    buildBoard() {
      if (!this.board) return;

      let i = 0;
      const columns = [],
        statuses = {};
      for (const status of this.board.statuses) {
        columns.push({
          id: status.id,
          name: status.name,
          tickets: [],
        });
        statuses[status.id] = i;
        i++;
      }

      for (const ticket of this.data.results) {
        const idx = statuses[ticket.status_id];
        if (idx !== undefined) {
          columns[idx].tickets.push(ticket);
        }
      }

      this.columns = columns;
    },
  },
  props: {
    board: Object,
  },
  watch: {
    async board() {
      await this.refresh();
      this.buildBoard();
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
/* Unfortunately @apply cannot be setup in codesandbox,
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  background: #f7fafc;
  border: 1px solid #4299e1;
}
</style>
