<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("clients.clients") }}
        <v-spacer />
        <v-btn @click="add" small color="primary">{{ $t("clients.add") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="pt-1 mb-1">
          <v-col cols="3">
            <v-text-field
              v-model="filter.search"
              outlined
              dense
              hide-details
              clearable
              :label="$t('clients.search')"
              @blur="refresh"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="filter.company"
              outlined
              dense
              hide-details
              clearable
              :items="companies"
              :label="$t('clients.company')"
              @blur="refresh"
            />
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="filter.group"
              outlined
              dense
              hide-details
              clearable
              :items="groups"
              item-value="id"
              item-text="name"
              :label="$t('clients.group')"
              @change="refresh"
            />
          </v-col>
        </v-row>
        <v-data-table
          dense
          :headers="headers"
          :loading="loading"
          :items="data.clients"
          :server-items-length="data.count"
          :mobile-breakpoint="0"
          class="clients elevation-1"
        >
          <template #[`item.company`]="{ item }">
            <span v-if="item.company_id">{{ item.company.name }}</span>
            <span v-else>&mdash;</span>
          </template>
          <template #[`item.group`]="{ item }">
            <span v-if="item.group_id">{{ groupNames[item.group_id] }}</span>
            <span v-else>&mdash;</span>
          </template>
          <template #[`item.contacts`]="{ item }">
            <v-tooltip v-if="item.phone" bottom>
              <template #activator="{ on }">
                <v-icon color="blue" small class="mx-1" v-on="on">mdi-phone</v-icon>
              </template>
              <span>{{ item.phone }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.email" bottom>
              <template #activator="{ on }">
                <v-icon color="orange" small class="mx-1" v-on="on">mdi-email</v-icon>
              </template>
              <span>{{ item.email }}</span>
            </v-tooltip>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn x-small color="primary" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
        <v-dialog v-model="editDialog" width="600" :fullscreen="mobile">
          <ClientForm
            :groups="groups"
            :client="item"
            @close="
              editDialog = false;
              refresh;
            "
          />
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BaseView from "../BaseView";
import ClientForm from "./ClientForm";

const baseURL = "/clients";

export default {
  name: "ClientsList",
  components: { ClientForm },
  mixins: [BaseView],
  computed: {
    ...mapState(["mobile"]),
  },
  watch: {
    async editDialog(value) {
      if (!value) {
        await this.refresh();
      }
    },
  },
  async created() {
    this.setTitle(this.$t("clients.clients"));
    this.checkPermission();

    try {
      const resp = await this.$api.get("/clients/groups");
      this.groups = resp.data.groups;
      for (const group of this.groups) {
        this.groupNames[group.id] = group.name;
      }
    } catch (error) {
      console.error("Error load client groups: ", error);
    }

    await this.refresh();
  },
  data() {
    return {
      perm: "clients",
      headers: [
        { text: this.$t("clients.full-name"), value: "name" },
        { text: this.$t("clients.contacts"), value: "contacts", align: "center", sortable: false },
        { text: this.$t("clients.group"), value: "group", align: "center", sortable: false },
        { text: this.$t("clients.company"), value: "company", align: "center", sortable: false },
        { text: this.$t("clients.comment"), value: "comment", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      editDialog: false,
      errors: {},
      item: {},
      companies: [],
      groups: [],
      groupNames: {},
      filter: { company: null, group: null, search: "" },
      data: { count: 0, page: 1, results: [] },
      loading: true,
      ordering: null,
      ordering_desc: false,
    };
  },
  methods: {
    add() {
      this.item = {};
      this.editDialog = true;
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));
      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.errors = {};
      this.editDialog = true;
    },
    async refresh() {
      const params = {
        company: this.filter.company,
        group: this.filter.group,
        search: this.filter.search,
        page: this.data.page,
      };

      this.loading = true;

      try {
        const resp = await this.$api.get(baseURL, params);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load clients list: ", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped>
.clients td {
  cursor: pointer;
}
</style>
