<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("account.groups") }}
        <v-spacer />
        <v-btn small color="primary" @click="add">{{ $t("account.add-group") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="accountGroups"
          :mobile-breakpoint="0"
          hide-default-footer
          class="elevation-1"
        >
          <template #item.perms="{ item }">
            <span v-if="item.perms_settings" class="mx-1 caption">{{ $t("settings") }}</span>
            <span v-if="item.perms_cash_shifts" class="mx-1 caption">{{ $t("cash-shifts") }}</span>
            <span v-if="item.perms_admin" class="mx-1 caption">{{ $t("admin") }}</span>
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="info" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("account.group") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="item.name" outlined dense :label="$t('name')" required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="item.perms_settings" dense :label="$t('settings')" />
            </v-col>
            <v-col cols="6">
              <v-checkbox v-model="item.perms_admin" dense :label="$t('admin')" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "../BaseView.vue";
import { mapState } from "vuex";

const baseURL = "/account/groups";

export default {
  name: "UserGroups",
  mixins: [BaseView],
  computed: {
    ...mapState(["accountGroups"]),
  },
  data() {
    return {
      perm: "admin",
      editDialog: false,
      headers: [
        { text: this.$t("name"), value: "name", sortable: false },
        { text: this.$t("account.permissions"), value: "perms", align: "center", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      item: { perms: {} },
    };
  },
  async created() {
    this.checkPermission();
  },
  methods: {
    add() {
      this.edit({ perms: {} });
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.errors = {};
      this.editDialog = true;
    },
    async save() {
      if (this.item.id) {
        try {
          let url = baseURL + "/" + this.item.id;
          const resp = await this.$api.patch(url, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          const resp = await this.$api.post(baseURL, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
  },
};
</script>
