<template>
  <v-app>
    <v-app-bar app clipped-left clipped-right color="white" dense>
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <v-spacer />
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn icon class="mr-1" v-on="on">
            <v-icon color="primary">mdi-web</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item @click="saveLocale(lang.id)" v-for="lang in languages" :key="lang.id">
            <v-list-item-title>{{ lang.name }}</v-list-item-title>
            <v-list-item-action>
              <v-icon small v-if="lang.id === locale" color="green">mdi-check</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu bottom left offset-y origin="top right" transition="scale-transition">
        <template #activator="{ on }">
          <v-btn icon class="mr-1" v-on="on">
            <v-avatar size="40">
              <v-img v-if="user.avatar" :src="user.avatar" />
              <v-icon v-else color="primary">mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item :to="{ name: 'settings-profile' }">
            <v-list-item-title>{{ $t("account.profile") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="showNotificationsSetup = true">
            <v-list-item-title>{{ $t("notifications.configure") }}</v-list-item-title>
          </v-list-item>
          <v-divider class="my-1" />
          <v-list-item @click="logout">
            <v-list-item-title>{{ $t("account.logout") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="blue-grey lighten-5">
      <router-view />
    </v-main>
    <v-navigation-drawer id="main-sidebar" v-model="drawer" app width="200">
      <div class="account">
        <v-img src="@/assets/images/logo.svg" width="80" class="mt-2" />
        <div class="body-2">{{ account.name }}</div>
      </div>
      <v-divider />
      <v-list dense class="pa-3">
        <template v-for="(item, i) in menu">
          <v-subheader v-if="item.header" :key="i" class="subtitle-2 black--text ml-2">
            {{ item.header }}
          </v-subheader>
          <v-divider v-else-if="item.divider" :key="i" class="my-1 mx-4" />
          <v-list-group
            active-class="active"
            v-else-if="item.items"
            v-model="item.active"
            :group="item.url"
            :key="i"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-action class="ma-0 mr-2" v-if="item.icon">
                <v-icon size="20">{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="(child, m) in item.items" :key="m" :link="!!child.to" :to="child.to" class="pl-4">
              <v-list-item-action class="ml-3 mr-2" v-if="child.icon">
                <v-icon size="20">{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content class="pa-0">
                <v-list-item-title>{{ child.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else :key="i" :to="item.to" :link="!!item.to">
            <v-list-item-action class="ma-0 mr-2" v-if="item.icon">
              <v-icon size="20">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content class="pa-0">
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-divider />
      <div class="text-center caption mt-2">&copy; Supportix, 2023</div>
    </v-navigation-drawer>
    <v-snackbar v-model="snackbar.show" top multi-line :color="snackbar.color" :timeout="snackbar.timeout">
      {{ snackbar.text }}
    </v-snackbar>
    <v-dialog v-model="showNotificationsSetup" width="500" :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-toolbar color="primary" dark flat dense>
          <v-toolbar-title>{{ $t("notifications.connect") }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="showNotificationsSetup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-4">
          <div class="py-3">{{ $t("notifications.description") }}</div>
          <v-btn small color="success" @click="setupNotifications">{{ $t("notifications.connect") }}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <TicketDialogs />
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { messaging, getPushToken } from "@/firebase";
import BaseView from "@/components/BaseView";
import LoadDataMixin from "@/components/mixins/LoadDataMixin";
import TicketDialogs from "@/components/tickets/TicketDialogs";
import { ENABLE_CALLS, ENABLE_KB, ENABLE_MAILBOXES, ENABLE_TICKETS } from "@/feature-flags";

const localeField = "lang";

export default {
  name: "DefaultLayout",
  components: { TicketDialogs },
  mixins: [BaseView, LoadDataMixin],
  computed: {
    ...mapState(["account", "boards", "knowledgeBases", "snackbar", "user"]),
    openThreadsCount() {
      let count = 0;
      for (let thread of this.$store.state.threads) {
        if (thread.status === "o") {
          count++;
        }
      }
      return count;
    },
  },
  async created() {
    this.$store.commit("setMobile", this.$vuetify.breakpoint.mobile);
    this.locale = localStorage.getItem(localeField) || "en";
    this.setLocale(this.locale);

    if ("Notification" in window) {
      this.notificationPermission = Notification.permission;
      this.messaging = messaging;
    }

    if (this.user.id === null) {
      let user = null;
      try {
        const resp = await this.$api.get("account/users/me");
        user = resp.data;
      } catch (error) {
        // no code
      }

      if (user) {
        this.$store.commit("setUser", user);
        await this.loadData();
        this.updateMenu();

        const that = this,
          loc = window.location;
        const wsUrl = loc.protocol.replace("http", "ws") + "//" + loc.host + "/api/chat/ws";
        await this.$ws.connect(wsUrl, this.$api.getToken(), (data) => {
          that.$store.commit("handleWSMessage", JSON.parse(data));
        });
      } else {
        await this.$router.replace({ name: "login" });
      }
    }
  },
  data() {
    return {
      drawer: !this.mobile,
      locale: null,
      menu: [],
      notifications: [],
      notificationPermission: "",
      showNotificationsSetup: false,
    };
  },
  async destroyed() {
    await this.$ws.close();
  },
  methods: {
    getSettingsMenu() {
      const items = [
        {
          name: this.$t("account.account"),
          to: { name: "settings-account" },
          icon: "mdi-information-outline",
        },
        {
          name: this.$t("account.users"),
          to: { name: "settings-users" },
          icon: "mdi-account-multiple",
        },
        {
          name: this.$t("account.groups"),
          to: { name: "settings-user-groups" },
          icon: "mdi-lightbulb-group",
        },
        {
          divider: true,
        },
        {
          name: this.$t("chats.chats"),
          to: { name: "settings-channels" },
          icon: "mdi-chat-processing",
        },
        {
          name: this.$t("chats.templates"),
          to: { name: "settings-templates" },
          icon: "mdi-forum",
        },
        // {
        //   name: this.$t("triggers.triggers"),
        //   to: { name: "settings-triggers" },
        //   icon: "mdi-swap-horizontal",
        //   color: "light-blue darken-3",
        // },
        // {
        //   name: this.$t("mail.mail"),
        //   to: { name: "settings-mail" },
        //   icon: "mdi-email-multiple",
        //   color: "light-blue darken-3",
        // },
        {
          name: this.$t("widgets.sites"),
          to: { name: "settings-widget-groups" },
          icon: "mdi-widgets",
        },
      ];

      if (ENABLE_TICKETS) {
        items.push({
          name: this.$t("tickets.tickets"),
          to: { name: "settings-tickets-boards" },
          icon: "mdi-ticket-confirmation",
        });
      }

      return items;
    },
    logout() {
      this.$api.setToken("");
      this.$router.push({ name: "login" });
    },
    saveLocale(locale) {
      localStorage.setItem(localeField, locale);
      this.setLocale(locale);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$root.$i18n.locale = locale;
      this.$moment.locale(locale);
      this.$forceUpdate();
      this.updateMenu();
    },
    updateMenu() {
      let items = [];
      const perms = { chats: 1, clients: 1, tickets: 1, telephony: 1, admin: 1 };

      if (perms.chats) {
        items.push({
          icon: "mdi-chat-processing",
          name: this.$t("chats.chats"),
          to: { name: "chats" },
          color: "teal darken-2",
          count: this.openThreadsCount,
        });
      }

      if (ENABLE_CALLS && perms.telephony) {
        items.push({
          icon: "mdi-phone",
          name: this.$t("calls.calls"),
          to: { name: "calls" },
          color: "light-blue darken-2",
        });
      }

      if (perms.clients) {
        items.push({
          icon: "mdi-account-group-outline",
          name: this.$t("clients.clients"),
          to: { name: "clients" },
          color: "indigo darken-2",
        });
      }

      if (perms.clients) {
        items.push({
          icon: "mdi-eye-outline",
          name: this.$t("visitors.visitors"),
          to: { name: "visitors" },
          color: "indigo darken-2",
        });
      }

      if (ENABLE_TICKETS && perms.tickets && this.boards.length > 0) {
        items.push({ header: this.$t("tickets.tickets") });
        for (const board of this.boards) {
          items.push({
            icon: "mdi-format-list-checks",
            name: board.name,
            to: { name: "board", params: { id: board.id } },
            color: "deep-purple darken-2",
          });
        }
        // if (this.boards.length === 0) {
        //   items.push({
        //     name: "Не сконфигурировано",
        //   });
        // }
      }

      if (ENABLE_MAILBOXES && perms.mail) {
        items.push({
          icon: "mdi-email-multiple",
          name: this.$t("mail.mail"),
          to: { name: "mail" },
          color: "green darken-2",
        });
      }

      if (ENABLE_KB) {
        items.push({ header: this.$t("kb.knowledge-bases") });
        for (const kb of this.knowledgeBases) {
          items.push({
            icon: "mdi-book-open-variant",
            name: kb.name,
            to: { name: "kb-pages", params: { kb: kb.id } },
            color: "deep-purple darken-2",
          });
        }
      }

      if (perms.admin) {
        items.push({ header: this.$t("account.settings") });
        items = items.concat(this.getSettingsMenu());
        // items.push({
        //   name: this.$t("account.settings"),
        //   icon: "mdi-cog",
        //   url: "settings",
        //   items: this.getSettingsMenu(isDev),
        // });
      }

      this.menu = items;
    },
    async setupNotifications() {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
        return;
      }

      let permission = Notification.permission;
      if (permission !== "denied") {
        permission = await Notification.requestPermission();
      }

      console.log("Notification permission", permission);
      if (permission === "granted") {
        const push_token = await getPushToken();
        if (push_token) {
          await this.$api.post("account/push-token", { push_token });
        }
      }

      this.showNotificationsSetup = false;
    },
  },
};
</script>

<style scoped lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .account {
    padding: 0 15px;
  }
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    padding: 8px 15px;

    .v-list-item {
      border-radius: 5px;
      height: 34px;

      &__icon--text,
      &__icon:first-child {
        justify-content: center;
        text-align: center;
        width: 20px;
      }

      .v-list-item__title {
        font-weight: 400 !important;
      }
    }
    .v-list-item__action .v-icon {
      color: rgb(93, 135, 255);
    }
    .v-list-item--active {
      color: #fff;
      background: rgb(93, 135, 255);
      .v-list-item__action .v-icon {
        color: #fff;
      }
    }
  }
}
</style>

<style>
.header {
  color: #000;
  padding-top: 7px;
}
.v-data-table table {
  border: solid 1px #ddd;
}
.v-data-table thead tr {
  background: #e1e1e1;
}
.v-data-table th {
  font-size: 0.85rem;
}
.vue-daterange-picker {
  width: 100% !important;
}
.vue-daterange-picker .reportrange-text {
  border: 0 !important;
  padding: 0 !important;
}
.vue-daterange-picker .reportrange-text input {
  text-align: center;
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.link {
  cursor: pointer;
}
</style>
