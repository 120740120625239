<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("visitors.visitors") }}
        <v-spacer />
        <v-text-field
          v-model="filter.search"
          outlined
          dense
          hide-details
          clearable
          class="font-weight-regular"
          :label="$t('clients.search')"
          @blur="refresh"
        />
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="data.visitors"
          :server-items-length="data.total"
          :mobile-breakpoint="0"
          class="visitors elevation-1"
        >
          <template #[`item.page`]="{ item }">
            <a :href="item.last_url" target="_blank">{{ item.last_title }}</a>
          </template>
          <template #[`item.last_visited`]="{ item }">
            {{ showDateTime(item.last_visited) }}
          </template>
          <template #[`item.client`]="{ item }">
            <span v-if="item.client_id">{{ item.client.name }}</span>
            <v-btn x-small v-else @click="chooseClient(item)" color="info">{{ $t("visitors.set-client") }}</v-btn>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn x-small color="primary" @click="showInfo(item)">
              <v-icon small>mdi-info</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="searchClientDialog" max-width="600">
      <SearchClientDialog @client="setClient" @close="closeClientDialog" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BaseView from "../BaseView";
import SearchClientDialog from "@/components/clients/SearchClientDialog.vue";

const baseURL = "/visitors";

export default {
  name: "ClientsList",
  mixins: [BaseView],
  components: { SearchClientDialog },
  computed: {
    ...mapState(["mobile"]),
  },
  watch: {
    async editDialog(value) {
      if (!value) {
        await this.refresh();
      }
    },
  },
  async created() {
    this.setTitle(this.$t("visitors.visitors"));
    this.checkPermission();
    await this.refresh();
  },
  data() {
    return {
      perm: "visitors",
      headers: [
        { text: this.$t("visitors.name"), value: "name" },
        {
          text: this.$t("visitors.pages"),
          value: "pages_count",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("visitors.page"),
          value: "page",
          sortable: false,
        },
        {
          text: this.$t("visitors.date"),
          value: "last_visited",
          sortable: false,
          align: "center",
        },
        {
          text: this.$t("visitors.client"),
          value: "client",
          sortable: false,
          align: "center",
        },
        // { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      filter: { company: null, group: null, search: "" },
      data: { total: 0, page: 1, visitors: [] },
      visitor: null,
      loading: true,
      searchClientDialog: false,
    };
  },
  methods: {
    chooseClient(visitor) {
      this.visitor = visitor;
      this.searchClientDialog = true;
    },
    closeClientDialog() {
      this.visitor = null;
      this.searchClientDialog = false;
    },
    async setClient(client) {
      await this.$api.patch("/visitors/" + this.visitor.id, { client_id: client.id });
      await this.refresh();
    },
    async refresh() {
      const params = {
        page: this.data.page,
      };

      this.loading = true;
      try {
        const resp = await this.$api.get(baseURL, params);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load visitors list: ", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
