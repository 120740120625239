<template>
  <v-card>
    <v-toolbar color="primary" dark dense flat>
      <v-toolbar-title>{{ $t("chats.add-template-message") }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list v-model="selected" dense>
      <template v-for="template in translatedTemplates">
        <v-list-item :key="template.uid" link @click="addText(template.text)">{{ template.text }}</v-list-item>
        <v-divider :key="'d' + template.uid" />
      </template>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ChatTemplates",
  computed: {
    ...mapState(["templates"]),
    translatedTemplates() {
      const templates = [],
        lang = this.thread.lang || "ru",
        context = { name: this.thread.name };

      for (const template of this.templates) {
        if (template.langs[lang]) {
          template.text = this.parseText(template.langs[lang], context);
          templates.push(template);
        }
      }

      return templates;
    },
  },
  props: {
    thread: Object,
  },
  data() {
    return {
      selected: null,
    };
  },
  methods: {
    addText(text) {
      this.$emit("text", text);
      this.$emit("close");
    },
    parseText(text, context) {
      const reg = /\{\{(.*?)\}\}/g;
      for (;;) {
        const found = reg.exec(text);
        if (!found) break;

        const variable = found[1].trim();
        if (context[variable]) {
          text = text.replace(found[0], context[variable]);
        } else {
          text = text.replace(found[0], "");
        }
      }
      return text;
    },
  },
};
</script>
