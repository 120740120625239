<template>
  <v-card>
    <v-card-title class="pa-4">
      {{ $t("widgets.support-settings") }}
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="6">
          <v-text-field v-model="widget.name" dense outlined hide-details :label="$t('widgets.support-button-name')" />
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="widget.title" dense outlined hide-details :label="$t('widgets.support-write-us')" />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="widget.left" dense outlined hide-details :label="$t('widgets.margin-left')" />
        </v-col>
        <v-col cols="4">
          <v-menu v-model="colorPicker" :close-on-content-click="false" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field
                v-model="color.hex"
                outlined
                dense
                hide-details
                :label="$t('widgets.support-button-color')"
                readonly
                :style="{ backgroundColor: color ? color.hex : '' }"
                v-on="on"
              />
            </template>
            <v-color-picker v-model="color" hide-inputs mode="rgba" />
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="widget.right" dense outlined hide-details :label="$t('widgets.margin-right')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" offset="4">
          <v-text-field v-model="widget.bottom" dense outlined hide-details :label="$t('widgets.margin-bottom')" />
        </v-col>
      </v-row>
      <v-divider class="my-2" />
      <h5>{{ $t("widgets.channels") }}</h5>
      <v-row v-for="(channel, i) in widget.channels" :key="i">
        <v-col>
          <v-select v-model="channel.id" outlined dense hide-details :items="channels" item-value="id" item-text="name">
            <template #item="{ item }">
              <div class="d-flex">
                <v-img :src="getChannelIcon(item.type)" width="20" />
                <div class="body-2 ml-2">{{ item.name }}</div>
              </div>
            </template>
            <template #selection="{ item }">
              <div class="d-flex">
                <v-img :src="getChannelIcon(item.type)" width="20" />
                <div class="body-2 ml-2">{{ item.name }}</div>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col>
          <v-text-field v-model="channel.name" outlined dense hide-details />
        </v-col>
        <v-col cols="1">
          <v-btn small icon color="primary" class="mt-2" @click="widget.channels.splice(i, 1)">
            <v-icon small color="red">mdi-delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-btn small color="primary" class="mt-2" @click="widget.channels.push({ name: '', id: '' })">
        <v-icon color="white">mdi-plus</v-icon>
      </v-btn>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" small @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseView from "@/components/BaseView.vue";

export default {
  name: "SupportButtonSettings",
  mixins: [BaseView],
  created() {
    Object.assign(this.widget, this.value);
    if (this.widget.color) {
      this.color = { hex: this.widget.color || "" };
    }
  },
  data() {
    return {
      color: { hex: "" },
      colorPicker: null,
      widget: {
        name: "",
        channels: [],
        title: this.$t("widgets.support-name"),
        color: "",
        write_us: this.$t("widgets.support-write-us"),
        left: "",
        right: 20,
        bottom: 20,
      },
    };
  },
  methods: {
    save() {
      if (this.widget.left) this.widget.left = parseInt(this.widget.left);
      if (this.widget.right) this.widget.right = parseInt(this.widget.right);
      if (this.widget.bottom) this.widget.bottom = parseInt(this.widget.bottom);
      this.widget.color = this.color.hex;
      this.$emit("input", this.widget);
      this.$emit("close");
    },
  },
  props: {
    channels: Array,
    value: Object,
  },
  watch: {
    value() {
      Object.assign(this.widget, this.value.data);
      if (this.widget.color) {
        this.color = { hex: this.widget.color || "" };
      }
    },
  },
};
</script>
