<template>
  <v-card>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>{{ $t("tickets.tickets") }}</v-toolbar-title>
      <v-spacer />
      <v-btn color="success" small @click="addTicket(board)"> <v-icon>mdi-plus</v-icon> {{ $t("add") }} </v-btn>
    </v-toolbar>
    <v-card-text class="pa-1 pt-2">
      <v-row>
        <v-col>
          <v-row>
            <v-col cols="6" sm="2">
              <v-text-field
                v-model="filter.search"
                outlined
                dense
                hide-details
                clearable
                :label="$t('search')"
                @change="refresh"
              />
            </v-col>
            <v-col cols="6" sm="2">
              <v-select
                v-model="filter.status"
                outlined
                dense
                hide-details
                clearable
                :label="$t('status')"
                item-text="name"
                item-value="id"
                :items="statuses"
                @change="refresh"
              >
                <template #item="{ item }">
                  <span class="body-2 mr-4">{{ item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" sm="2">
              <v-select
                v-model="filter.user"
                outlined
                dense
                hide-details
                clearable
                :label="$t('user')"
                item-text="name"
                item-value="id"
                :items="users"
                @change="refresh"
              >
                <template #item="{ item }">
                  <span class="body-2 mr-4">{{ item.name }}</span>
                  <small>{{ item.type_name | lower }}, {{ item.currency | lower }}</small>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" sm="1" class="d-none d-md-block">
              <v-btn color="primary" outlined @click="exportData">{{ $t("export") }}</v-btn>
            </v-col>
            <v-col cols="6" sm="1" class="text-right">
              <v-btn color="primary" outlined @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
            </v-col>
          </v-row>
          <hr class="my-2" />
          <v-data-table
            dense
            :headers="headers"
            :loading="loading"
            :items="data.results"
            :sort-by.sync="ordering"
            :sort-desc.sync="ordering_desc"
            :server-items-length="data.count"
            :items-per-page="data.per_page"
            :loading-text="$t('table-loading')"
            :no-data-text="$t('table-no-data')"
            :mobile-breakpoint="0"
            hide-default-footer
            class="elevation-1"
          >
            <template #item.status="{ item }">
              <v-chip v-if="item.status === 'n'" color="primary" small>{{ $t("tickets.new") }}</v-chip>
              <v-chip v-if="item.status === 'o'" color="success" small>{{ $t("tickets.open") }}</v-chip>
              <v-chip v-if="item.status === 'a'" color="info" small>{{ $t("tickets.answered") }}</v-chip>
              <v-chip v-if="item.status === 'c'" color="secondary" small>{{ $t("tickets.closed") }}</v-chip>
            </template>
            <template #item.created_at="{ item }">
              {{ showDateTime(item.created_at, true) }}
            </template>
            <template #item.number="{ item }">
              <a @click="showTicket(item)">{{ item.number }}</a>
            </template>
            <template #item.title="{ item }">
              <a @click="showTicket(item)">{{ item.title }}</a>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination v-model="data.page" :length="data.pages" @input="updatePage" />
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import BaseView from "../BaseView";
import TicketsMixin from "./TicketsMixin";

export default {
  name: "TicketsList",
  mixins: [BaseView, TicketsMixin],
  props: {
    board: Object,
  },
  data() {
    return {
      perm: "tickets",
      headers: [
        { text: this.$t("status"), value: "status", align: "center" },
        { text: this.$t("tickets.time"), value: "created_at", align: "center" },
        { text: this.$t("tickets.title"), value: "title", sortable: false },
        { text: this.$t("clients.client"), value: "client.name", align: "center" },
        { text: this.$t("account.user"), value: "user.name", align: "center" },
      ],
      filter: { search: "", client: null, user: null, status: null },
      data: { count: 0, page: 1, results: [] },
      item: {},
      loading: true,
      ordering: null,
      ordering_desc: false,
      sessions: [],
      statuses: [
        { id: "open", name: this.$t("tickets.open") },
        { id: "closed", name: this.$t("tickets.closed") },
        { id: "wait", name: this.$t("tickets.wait-client") },
      ],
      users: [],
    };
  },
  watch: {
    async ordering() {
      await this.refresh();
    },
    async ordering_desc() {
      await this.refresh();
    },
  },
  async created() {
    this.setTitle(this.$t("tickets.tickets"));
    this.checkPermission();
    await this.refresh();
  },
};
</script>
