import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import API from "./api";
import { messaging } from "./firebase";
import router from "./router";
import store from "./store";
import WS from "./ws";

import CabinetLayout from "./components/layout/Cabinet";
import BlankLayout from "./components/layout/Blank";
import i18n from "./plugins/i18n";

Vue.config.productionTip = false;
const moment = require("moment");
Vue.prototype.$messaging = messaging;

Vue.use(API);
Vue.use(WS);
Vue.use(require("vue-moment"), {
  moment,
});

Vue.component("CabinetLayout", CabinetLayout);
Vue.component("BlankLayout", BlankLayout);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue: Vue,
    dsn: "https://c3f114eab5cb4802b7584c1952493b36@o53599.ingest.sentry.io/5281454",
    integrations: [new Integrations.BrowserTracing()],
  });
}

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
