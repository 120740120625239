<template>
  <div>
    <div
      v-if="message.type === 'c' || message.type === 's'"
      class="d-flex justify-space-between grey lighten-4 rounded caption pa-1 px-2 mb-3"
    >
      <div style="width: 75px" />
      <div class="text-center flex-shrink-1">{{ command }}</div>
      <div class="time">{{ showDateTime(message.created) }}</div>
    </div>
    <div v-else class="d-flex mb-3" :class="{ user: !message.user_id }">
      <div class="thumb">
        <v-avatar size="36" class="mx-2" :color="getAvatarColor">
          <v-img v-if="avatar" :src="avatar" width="32" />
          <span v-else class="white--text">{{ name | initials }}</span>
        </v-avatar>
      </div>
      <div class="message pa-2">
        <div class="d-flex">
          <div v-if="name" class="font-weight-bold">{{ name }}</div>
          <div v-else class="caption">({{ $t("chats.no-name") }})</div>
          <v-spacer />
          <div class="text-right caption ml-2">{{ showDateTime(message.created) }}</div>
        </div>
        <iframe
          v-if="message.type === 'm'"
          :srcdoc="message.content"
          seamless
          frameborder="0"
          width="100%"
          onload="this.style.height=(this.contentWindow.document.body.scrollHeight+20)+'px';"
        />
        <div v-if="message.type === 't'" v-html="adaptMessage(message.content)" />
        <div v-if="message.type === 'i'" class="text-right">
          <v-img max-height="300" max-width="300" :src="previewUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChatMixin from "./ChatMixin";

export default {
  name: "ChatMessage",
  mixins: [ChatMixin],
  props: {
    message: Object,
    thread: Object,
  },
  computed: {
    avatar() {
      return this.message.user_id ? this.user?.avatar : this.thread.avatar;
    },
    getAvatarColor() {
      if (!this.message.name) return null;
      return "brown";
    },
    command() {
      const key = this.message.content.replace("chat.", "chats.commands.");
      return this.$te(key) ? this.$t(key) : this.message.content;
    },
    name() {
      if (this.message.user_id) {
        return this.user?.name;
      } else {
        return this.thread.client ? this.thread.client.name : this.thread.name || "";
      }
    },
    previewUrl() {
      return "https://cdn.supportix.net/files/" + this.message.preview_url;
    },
    user() {
      return this.$store.getters.getUser(this.message.user_id);
    },
  },
  methods: {
    adaptMessage(text) {
      // escape any html tags
      text = text.replaceAll(/&/g, "&amp;").replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;");

      // linkify links
      text = text.replaceAll(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

      // add new lines
      text = text.replaceAll("\n", "<br />");

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  border-radius: 5px;
  background: #f2f2f2;
  font-size: 0.9rem;
  flex-grow: 1;
}

.user {
  .message {
    background: #f0f8ff;
  }
}
</style>
