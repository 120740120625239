<template>
  <v-container fluid class="chat-app">
    <v-card class="d-flex flex-row height-100">
      <div v-show="showThreads" class="chats height-100 d-flex flex-column" :class="{ 'full-width': mobile }">
        <div class="pa-1 px-2 d-flex">
          <v-text-field
            v-model="search"
            clearable
            outlined
            dense
            hide-details
            class="mt-2"
            :placeholder="$t('chats.type-to-search')"
            @click:clear="search = ''"
          />
          <v-menu bottom left offset-y origin="top right" transition="scale-transition">
            <template #activator="{ on }">
              <v-btn dark icon class="mt-2" v-on="on"><v-icon color="blue">mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list dense>
              <v-list-item v-for="filter in chatFilters" :key="filter.id" @click="toggleFilter(filter)">
                <v-list-item-title>{{ filter.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-divider class="mt-1" />
        <div v-if="threadsFilters.length > 0" class="pa-2">
          <v-chip
            v-for="filter in threadsFilters"
            :key="filter.id"
            close
            small
            color="primary"
            class="mr-1"
            @click:close="toggleFilter(filter)"
          >
            {{ filter.name }}
          </v-chip>
        </div>
        <v-divider v-if="threadsFilters.length > 0" />
        <v-list nav dense three-line class="overflow-y-auto flex-grow-1">
          <v-list-item-group v-model="selected">
            <ThreadRow
              v-for="(thread, i) in filteredThreads"
              :data-id="thread.id"
              :key="thread.id"
              :thread="thread"
              @click="setThread(thread, i)"
            />
          </v-list-item-group>
          <div v-if="filteredThreads.length === 0" class="mt-5 text-center caption">
            {{ $t("chats.no-chats") }}
          </div>
        </v-list>
      </div>
      <div class="messages-area height-100">
        <Messages
          v-if="selectedThread"
          :thread="selectedThread"
          :statuses="statuses"
          @toggleChatInfo="showChatInfo = !showChatInfo"
          @clearThread="selectedThread = null"
        />
      </div>
      <div v-if="!mobile" class="info-panel">
        <ChatInfo v-if="showChatInfo" :thread="selectedThread" :statuses="statuses" />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";

import BaseView from "../BaseView";
import Messages from "./Messages";
import ChatInfo from "./ChatInfo";
import ThreadRow from "@/components/chats/ThreadRow";

export default {
  name: "Chat",
  components: { ThreadRow, ChatInfo, Messages },
  mixins: [BaseView],
  computed: {
    ...mapState(["mobile", "threads", "user"]),
    chatFilters() {
      return [
        { id: "user_id:null", name: this.$t("chats.new") },
        { id: "user_id:" + this.user.id, name: this.$t("chats.my") },
        { id: "status:a", name: this.$t("chats.archive") },
      ];
    },
    filteredThreads() {
      const search = this.search.trim().toLowerCase();

      return this.threads.filter((thread) => {
        let result = false;

        if (this.threadsFilters.length > 0) {
          for (const filter of this.threadsFilters) {
            const [field, value] = filter.id.split(":");
            const threadValue = thread[field] === null ? "null" : thread[field].toString();
            if (threadValue === value) {
              result = true;
              break;
            }
          }
        } else {
          result = true;
        }

        if (search && result) {
          if (thread.name.toLowerCase().indexOf(search) === -1) result = false;
        }

        return result;
      });
    },
    showThreads() {
      if (this.mobile) {
        return this.selectedThread === null;
      }
      // show always for non-mobile
      return true;
    },
  },
  watch: {
    async threads() {
      if (this.openThreadById) {
        for (let n in this.threads) {
          if (this.threads[n].id === this.$route.params.id) {
            this.setThread(this.threads[n], n);
            this.openThreadById = false;
          }
        }
      }
    },
  },
  async created() {
    this.setTitle(this.$t("chats.chats"));
    this.checkPermission();

    // open thread by id param from URL
    this.openThreadById = !!this.$route.params.id;

    await this.loadTemplates();
  },
  data() {
    return {
      perm: "chat",
      showChatInfo: false,
      openThreadById: false,

      threadsFilters: [],
      search: "",
      selected: null,
      selectedThread: null,

      statuses: {
        a: this.$t("chats.answered"),
        o: this.$t("chats.open"),
        b: this.$t("chats.blocked"),
        f: this.$t("chats.finished"),
      },
    };
  },
  methods: {
    async loadTemplates() {
      if (this.$store.state.templates.length === 0) {
        try {
          const resp = await this.$api.get("chat/templates");
          this.$store.commit("setTemplates", resp.data.templates);
        } catch (err) {
          console.error("Can't load templates", err);
        }
      }
    },
    setThread(thread, index) {
      if (!this.mobile) {
        this.showChatInfo = true;
      }

      this.selected = index;
      if (!this.selectedThread || thread.id !== this.selectedThread.id) {
        this.selectedThread = thread;
      }
    },
    toggleFilter(filter) {
      if (this.threadsFilters.includes(filter)) {
        const index = this.threadsFilters.indexOf(filter);
        if (index > -1) {
          this.threadsFilters.splice(index, 1);
        }
      } else {
        this.threadsFilters.push(filter);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.chat-app {
  overflow: hidden;
  position: relative;
  height: calc(100vh - 70px);
  .chats {
    min-width: 350px;
    max-width: 350px;
  }
  .messages-area {
    overflow-y: auto;
    width: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  .info-panel {
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
}
</style>
