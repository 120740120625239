<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("calls.calls") }}
      </v-card-title>
      <v-card-text className="pa-1">
        <v-alert type="warning" class="mb-3">{{ $t("general.module-is-not-connected") }}</v-alert>
        <CallsTable :search-params="searchParams" :can-edit="canEdit" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import CallsTable from "./CallsList.vue";
import BaseView from "@/components/BaseView";

export default {
  name: "CallsList",
  components: { CallsTable },
  mixins: [BaseView],
  computed: {
    canEdit() {
      return true;
    },
  },
  created() {
    this.setTitle(this.$t("calls.calls"));
  },
  data() {
    return {
      perm: "calls",
      searchParams: {},
    };
  },
  methods: {
    search(params) {
      this.searchParams = params;
    },
  },
};
</script>
