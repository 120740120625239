<template>
  <v-container fluid class="chat-app height-100">
    <v-card class="d-flex flex-row height-100">
      <div v-show="showChatList" class="chats" :class="{ 'full-width': $vuetify.breakpoint.mobile }">
        <div class="pa-1 px-2 border-bottom">
          <v-text-field v-model="search" outlined dense hide-details :placeholder="$t('type-to-search')" class="mt-2" />
        </div>
        <v-list nav dense two-line>
          <v-list-item-group v-model="selected">
            <template v-for="(thread, i) in filteredThreads">
              <v-list-item
                :key="i"
                class="my-1"
                :class="{
                  'thread-open': thread.status === 'o',
                  'thread-answered': thread.status === 'a',
                  'thread-closed': thread.status === 'b',
                }"
                @click="setThread(thread, i)"
              >
                <v-avatar size="40" class="mr-3">
                  <v-img :src="getAvatar(thread)" width="40" />
                </v-avatar>
                <v-list-item-content>
                  <v-list-item-title class="d-flex">
                    <div class="name black--text mr-2">{{ thread.name }}</div>
                    <v-chip v-if="thread.status === 'c'" x-small color="grey">{{ $t("thread-closed") }}</v-chip>
                    <v-spacer />
                    <div class="time">{{ getThreadTime(thread) }}</div>
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ thread.last_message_text }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'d' + i" />
            </template>
          </v-list-item-group>
        </v-list>
      </div>
      <div class="chat-messages-area height-100"></div>
    </v-card>
  </v-container>
</template>

<script>
import BaseView from "../BaseView";

export default {
  name: "MailList",
  mixins: [BaseView],
  data() {
    return {
      perm: "chat",
      drawer: true,
      showChatInfo: false,
      selected: null,
      selectedThread: null,
      search: "",
      totalChatHeight: 0,
      text: "",
    };
  },
  computed: {
    filteredThreads() {
      return this.threads.filter((thread) => {
        return !this.search || thread.name.indexOf(this.search) > -1;
      });
    },
    showChatList() {
      if (this.$vuetify.breakpoint.mobile) {
        return this.selectedThread === null;
      }
      // show always for non-mobile
      return true;
    },
    threads() {
      return this.$store.state.threads;
    },
  },
  watch: {
    async threads() {
      if (this.openThreadById) {
        for (let n in this.threads) {
          if (this.threads[n].id === this.$route.params.id) {
            this.selected = n;
            await this.setThread(this.threads[n]);

            this.openThreadById = false;
          }
        }
      }
    },
  },
  async created() {
    this.setTitle(this.$t("chats"));
    this.checkPermission();

    // open thread by id param from URL
    this.openThreadById = !!this.$route.params.id;
  },
  methods: {
    getAvatar(thread) {
      if (thread.avatar) return thread.avatar;
      if (!thread.type) return null;
      return require("@/assets/icons/" + thread.type + ".svg");
    },
    getThreadTime(thread) {
      if (!thread.last_message_dt) return "";
      return this.$moment(thread.last_message_dt).fromNow(true);
    },
    async setThread(thread, index) {
      if (!this.$vuetify.breakpoint.mobile) {
        this.showChatInfo = true;
      }

      this.selected = index;
      if (!this.selectedThread || thread.id !== this.selectedThread.id) {
        this.selectedThread = thread;
        await this.$ws.sendEvent("messages", { thread_id: this.selectedThread.id });
      }
    },
  },
};
</script>

<style lang="scss">
.chat-app {
  overflow: hidden;

  .border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .chats {
    min-width: 350px;
    max-width: 350px;
    .v-list-item__subtitle {
      white-space: break-spaces;
      font-size: 80%;
    }

    .thread-answered {
      .v-list-item__subtitle {
        font-weight: normal;
      }
    }
    .thread-open {
      font-weight: bold;
      .v-list-item__subtitle {
        color: #363636;
      }
    }
    .thread-closed {
      font-weight: normal;
      background: #e1e1e1;
    }

    .time {
      color: #797979;
      font-weight: 400;
    }
  }
  .chat-messages-area {
    overflow-y: auto;
    width: 100%;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
  .chat-info {
    width: 400px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }
}
</style>
