<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        {{ $t("account.users") }}
        <v-spacer />
        <v-btn small color="primary" @click="add">{{ $t("account.add-user") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" :loading="loading" :mobile-breakpoint="0" class="elevation-1">
          <template #item.name="{ item }"> {{ item.first_name }} {{ item.last_name }} </template>
          <template #item.group="{ item }">
            {{ (getAccountGroup(item.group_id) || {}).name }}
          </template>
          <template #item.status="{ item }">
            <v-icon v-if="item.status === 'a'" color="green">mdi-check-circle</v-icon>
            <v-icon v-if="item.status === 'v'" color="orange">mdi-beach</v-icon>
            <v-icon v-if="item.status === 'f'">mdi-minus-circle</v-icon>
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="info" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("account.user") }} #{{ item.id }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.first_name"
                outlined
                dense
                hide-details="auto"
                :label="$t('clients.first-name')"
                class="required"
                :error-messages="errors.first_name"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.last_name"
                outlined
                dense
                hide-details="auto"
                :label="$t('clients.last-name')"
                class="required"
                :error-messages="errors.last_name"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.email"
                outlined
                dense
                hide-details="auto"
                type="email"
                label="E-mail"
                class="required"
                :error-messages="errors.email"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.phone"
                outlined
                dense
                hide-details="auto"
                type="phone"
                :label="$t('account.phone')"
                class="required"
                :error-messages="errors.phone"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="item.position"
                outlined
                dense
                hide-details="auto"
                :label="$t('account.position')"
                :error-messages="errors.position"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="item.group_id"
                outlined
                dense
                class="required"
                hide-details="auto"
                item-value="id"
                item-text="name"
                :items="accountGroups"
                :label="$t('account.group')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="item.lang"
                outlined
                dense
                class="required"
                hide-details="auto"
                item-value="id"
                item-text="name"
                :items="languages"
                :label="$t('account.lang')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="item.langs"
                outlined
                dense
                multiple
                hide-details="auto"
                item-value="id"
                item-text="name"
                :items="languages"
                :label="$t('account.languages')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                v-model="item.status"
                outlined
                dense
                class="required"
                hide-details="auto"
                item-value="id"
                item-text="name"
                :items="statuses"
                :label="$t('status')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseView from "../BaseView.vue";

const baseURL = "/account/users";

export default {
  name: "SettingsUsers",
  mixins: [BaseView],
  computed: {
    ...mapState(["accountGroups"]),
    ...mapGetters(["getAccountGroup"]),
  },
  async created() {
    this.setTitle(this.$t("account.users"));
    this.checkPermission();
    await this.refresh();
  },
  data() {
    return {
      perm: "admin",
      editDialog: false,
      headers: [
        { text: this.$t("clients.full-name"), value: "name", sortable: false },
        { text: this.$t("E-mail"), value: "email", sortable: false },
        { text: this.$t("account.group"), value: "group", align: "center", sortable: false },
        { text: this.$t("account.position"), value: "position", aling: "center", sortable: false },
        { text: this.$t("status"), value: "status", align: "center", sortable: false },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      errors: {},
      items: [],
      item: {},
      loading: false,
      statuses: [
        { id: "a", name: this.$t("account.status-a") },
        { id: "v", name: this.$t("account.status-v") },
        { id: "f", name: this.$t("account.status-f") },
      ],
    };
  },
  methods: {
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.errors = {};
      this.editDialog = true;
    },
    async save() {
      delete this.item.avatar;

      if (this.item.id) {
        try {
          let url = baseURL + "/" + this.item.id;
          const resp = await this.$api.patch(url, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          const resp = await this.$api.post(baseURL, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
    async refresh() {
      try {
        const resp = await this.$api.get(baseURL);
        this.items = resp.data.users;
      } catch (error) {
        console.error("Error load users list: ", error);
      }
    },
  },
};
</script>
