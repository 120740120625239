<template>
  <div v-show="!loading" class="py-3">
    <dl v-if="currentThread.type === 'em' && currentThread.info" class="px-3">
      <dt v-if="currentThread.info.name" class="subtitle-2">{{ $t("mail.from") }}</dt>
      <dd v-if="currentThread.info.name" class="body-2">
        {{ currentThread.info.name }}
        <div v-if="currentThread.info.from" class="caption text--lighten-1">{{ currentThread.info.from }}</div>
      </dd>

      <dt v-if="currentThread.info.subject" class="subtitle-2">{{ $t("mail.subject") }}</dt>
      <dd v-if="currentThread.info.subject" class="body-2">{{ currentThread.info.subject }}</dd>

      <v-btn v-if="!currentThread.is_spam" x-small color="info" class="mb-1" @click="markAsSpam">
        {{ $t("mail.spam") }}
      </v-btn>
    </dl>
    <dl v-else class="px-3">
      <dt class="subtitle-2">{{ $t("name") }}</dt>
      <dd :id="thread.id" class="body-2">
        <div v-show="!editName">
          {{ currentThread.name | mdash }}
          <v-icon class="ml-4" small color="blue" @click="editName = !editName">mdi-pencil</v-icon>
        </div>
        <div v-show="editName">
          <v-text-field v-model="currentThread.name" dense outlined hide-details @blur="saveThread('name')" />
        </div>
      </dd>
    </dl>

    <dl class="px-3">
      <dt class="subtitle-2">{{ $t("chats.channel") }}</dt>
      <dd v-if="thread.channel_name" class="body-2">{{ thread.channel_name }}</dd>
    </dl>
    <v-divider class="mb-2" />

    <dl class="px-3">
      <dt class="subtitle-2">{{ $t("chats.group") }}</dt>
      <dd class="body-2">
        <span v-if="currentThread.group_id">{{ getGroupName }}</span>
        <span v-else>&mdash;</span>
        <v-btn v-show="!editGroup" small icon @click="editGroup = true"><v-icon small>mdi-pencil</v-icon></v-btn>
        <v-select
          v-show="editGroup"
          v-model="currentThread.group_id"
          dense
          outlined
          hide-details
          small
          :items="accountGroups"
          item-value="id"
          item-text="name"
          @change="saveThread('group_id')"
          @blur="saveThread('group_id')"
        />
      </dd>
    </dl>

    <dl class="px-3">
      <dt class="subtitle-2">{{ $t("chats.user") }}</dt>
      <dd class="body-2">
        <span v-if="currentThread.user_id">{{ getUserName }}</span>
        <span v-else>&mdash;</span>
      </dd>
    </dl>

    <dl class="px-3" v-if="currentThread.conversation">
      <dt class="subtitle-2">{{ $t("chats.conversation-opened") }}</dt>
      <dd class="body-2">{{ showDateTime(currentThread.conversation.open_time) }}</dd>
      <dt class="subtitle-2" v-if="isClosed">{{ $t("chats.conversation-resolved") }}</dt>
      <dd class="body-2" v-if="isClosed">{{ currentThread.conversation.resolved | yesno }}</dd>
      <dt class="subtitle-2" v-if="isClosed">{{ $t("chats.conversation-closed") }}</dt>
      <dd class="body-2" v-if="isClosed">{{ showDateTime(currentThread.conversation.close_time) | mdash }}</dd>
      <dt class="subtitle-2" v-if="isClosed">{{ $t("chats.conversation-review") }}</dt>
      <dd class="body-2" v-if="isClosed">{{ currentThread.conversation.client_comment | mdash }}</dd>
    </dl>

    <div class="text-center" v-if="!isClosed">
      <v-btn small color="info" @click="closeConversation">{{ $t("chats.conversation-close") }}</v-btn>
    </div>
    <v-divider class="my-2" />

    <dl class="px-3">
      <dt class="subtitle-2">{{ $t("clients.client") }}</dt>
      <dd class="body-2">
        <span v-if="currentThread.client">{{ currentThread.client.name }}</span>
        <span v-else>&mdash;</span>
        <v-btn small icon @click="clientSearchDialog = true"><v-icon small>mdi-magnify</v-icon></v-btn>
      </dd>

      <dt v-if="hasEmail" class="subtitle-2">E-mail</dt>
      <dd v-if="hasEmail" class="body-2">
        {{ currentThread.client.email }}
        <v-btn small icon @click="clientSearchDialog = true"><v-icon small>mdi-email-edit</v-icon></v-btn>
      </dd>

      <dt class="subtitle-2">{{ $t("clients.comment") }}</dt>
      <dd class="body-2">
        <div class="d-flex align-center">
          <div v-if="currentThread.comment" v-html="commentHTML"></div>
          <div v-else>&mdash;</div>
          <v-btn v-show="!editComment" small icon @click="editComment = true"><v-icon small>mdi-pencil</v-icon></v-btn>
        </div>
        <v-textarea
          v-show="editComment"
          v-model="currentThread.comment"
          dense
          outlined
          hide-details
          clearable
          auto-grow
          rows="2"
          @blur="saveThread('comment')"
        />
      </dd>

      <dt v-show="currentThread.client_id" class="subtitle-2">{{ $t("tickets.tickets") }}</dt>
      <dd v-show="currentThread.client_id">
        <div v-for="ticket in tickets" :key="ticket.id" class="ticket">
          <v-chip x-small :color="getTicketColor(ticket)">{{ ticket.status_name }}</v-chip>
          <span class="caption link ml-1" @click="showTicket(ticket)">
            {{ ticket.title }}
          </span>
        </div>
        <div v-if="tickets.length === 0" class="mt-1 body-2 grey--text lighten-1">{{ $t("tickets.not-found") }}</div>
        <v-btn small color="info" class="mt-2" @click="newTicketDialog = true">{{ $t("tickets.add") }}</v-btn>
      </dd>
    </dl>
    <v-dialog v-model="clientSearchDialog" width="600" :fullscreen="mobile">
      <ClientsSearchDialog :client="thread.client" @close="closeClientDialog" />
    </v-dialog>
    <v-dialog v-model="ticketDialog" width="600" :fullscreen="mobile">
      <Ticket :id="ticketId" @close="ticketDialog = false" />
    </v-dialog>
    <v-dialog v-model="newTicketDialog" width="600" :fullscreen="mobile">
      <NewTicketForm :client="currentThread.client" @close="closeTicketDialog" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import BaseView from "@/components/BaseView";
import ClientsSearchDialog from "@/components/clients/SearchClientDialog.vue";
import NewTicketForm from "@/components/tickets/NewTicketForm";
import Ticket from "@/components/tickets/TicketView";
import ChatMixin from "./ChatMixin";
import LoadDataMixin from "@/components/mixins/LoadDataMixin";

export default {
  name: "ChatInfo",
  components: { NewTicketForm, Ticket, ClientsSearchDialog },
  mixins: [BaseView, ChatMixin, LoadDataMixin],
  computed: {
    ...mapState(["accountGroups", "mobile", "users"]),
    isClosed() {
      return this.currentThread.conversation && !!this.currentThread.conversation.close_time;
    },
    commentHTML() {
      return this.$options.filters.nl2br(this.currentThread.comment);
    },
    getGroupName() {
      const group = this.$store.getters.getAccountGroup(this.currentThread.group_id);
      return group ? group.name : "-";
    },
    getUserName() {
      const user = this.$store.getters.getUser(this.currentThread.user_id);
      return user ? user.name : "-";
    },
    hasEmail() {
      return this.currentThread.client && this.currentThread.client.email;
    },
  },
  watch: {
    async thread() {
      this.currentThread = this.thread;
    },
  },
  async created() {
    await this.loadThread();

    if (this.accountGroups.length === 0) {
      await this.loadAccountGroups();
    }
  },
  props: {
    statuses: Object,
    thread: Object,
  },
  data() {
    return {
      clientSearchDialog: false,
      currentThread: {},

      editComment: false,
      editGroup: false,
      editName: false,
      editStatus: false,
      editUser: false,

      loading: false,
      tickets: [],

      newTicketDialog: false,
      ticketDialog: false,

      ticketId: null,
      tab: 0,
    };
  },
  methods: {
    async closeClientDialog(client) {
      if (client) {
        try {
          const resp = await this.$api.patch("/chat/threads/" + this.currentThread.id, { client_id: client.id });
          if (resp.success) {
            this.currentThread = resp.data;
            await this.loadTickets();
          }
        } catch (err) {
          console.error("Error search clients", err);
        }
      }
      this.clientSearchDialog = false;
    },
    async closeConversation() {
      await this.$api.post("/chat/threads/" + this.currentThread.id + "/close", { resolved: true });
    },
    async closeTicketDialog() {
      this.newTicketDialog = false;
      await this.loadTickets();
    },
    async loadTickets() {
      if (!this.currentThread.client) return;

      try {
        const resp = await this.$api.get("/tickets?client=" + this.currentThread.client.id);
        if (resp.success) {
          this.tickets = resp.data.results;
        }
      } catch (error) {
        console.error("Error load client tickets", error);
      }
    },
    async loadThread() {
      this.loading = true;
      try {
        const resp = await this.$api.get("/chat/threads/" + this.thread.id);
        if (resp.success) {
          this.currentThread = resp.data;
        }
      } catch (error) {
        console.error("Error load thread data", error);
      }
      this.loading = false;
    },
    async markAsSpam() {
      this.currentThread.is_spam = true;
      await this.saveThread("is_spam");
    },
    showTicket(ticket) {
      this.ticketDialog = true;
      this.ticketId = ticket.id;
    },
    async saveThread(field) {
      const data = {};
      data[field] = this.currentThread[field];

      const updatedThread = await this.updateThread(this.currentThread, data);
      if (updatedThread) {
        this.currentThread = updatedThread;
      }
      this.editComment = false;
      this.editGroup = false;
      this.editName = false;
      this.editUser = false;
    },
  },
};
</script>

<style scoped lang="scss">
dd {
  margin-bottom: 7px;
}
</style>
