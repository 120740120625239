<template>
  <div>
    <v-dialog v-model="newTicketDialog" persistent max-width="600px" :fullscreen="$vuetify.breakpoint.mobile">
      <NewTicketForm v-if="ticket" :board="ticket.board" :client="ticket.client" @close="closeTicket" />
    </v-dialog>
    <v-dialog v-model="ticketDialog" persistent max-width="800px" :fullscreen="$vuetify.breakpoint.mobile">
      <Ticket v-if="ticket" :id="ticket.id" :board="ticket.board" @close="closeTicket" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import NewTicketForm from "./NewTicketForm";
import Ticket from "./TicketView";

export default {
  name: "TicketDialogs",
  components: { NewTicketForm, Ticket },
  computed: {
    ...mapState(["ticket"]),
  },
  data() {
    return {
      newTicketDialog: false,
      ticketDialog: false,
    };
  },
  methods: {
    closeTicket() {
      this.$store.commit("setTicket", null);
    },
  },
  watch: {
    ticket() {
      this.newTicketDialog = false;
      this.ticketDialog = false;

      if (!this.ticket) return;
      if (this.ticket.id) {
        this.ticketDialog = true;
      } else {
        this.newTicketDialog = true;
      }
    },
  },
};
</script>
