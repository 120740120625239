<template>
  <div>
    <v-card flat>
      <v-card-title>
        {{ $t("widgets.sites") }}
        <v-spacer />
        <div class="ml-10">
          <v-btn small color="primary" :to="{ name: 'settings-widget-group-edit', params: { id: 'new' } }">
            {{ $t("widgets.add") }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" class="elevation-1">
          <template #item.widgets="{ item }">
            <div v-if="item.support">
              <v-img
                v-for="ch in item.support.channels"
                :key="ch.type"
                :src="getChannelIcon(ch.type)"
                height="20"
                width="20"
                class="d-inline-block mr-2"
              />
            </div>
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="info" :to="{ name: 'settings-widget-group-edit', params: { id: item.id } }">
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BaseView from "../BaseView.vue";

const baseURL = "/widgets/groups";

export default {
  name: "SettingsWidgets",
  mixins: [BaseView],
  data() {
    return {
      perm: "widgets",
      headers: [
        { text: this.$t("widgets.domains"), value: "hosts", width: "20%", sortable: false },
        { text: this.$t("widgets.configured-widgets"), value: "widgets", width: "70%", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      items: [],
      loading: false,
    };
  },
  async created() {
    this.setTitle(this.$t("widgets.widgets"));
    this.checkPermission();
    await this.refresh();
  },
  methods: {
    add() {
      this.item = {
        id: null,
        support: {
          id: null,
          name: "",
          left: 0,
          bottom: 0,
          right: 0,
          color: "",
          channels: [],
        },
      };
      this.editDialog = true;
    },
    edit(item) {
      this.item = item;
      if (item.support) {
        this.supportColor.hex = item.support.color;
      }

      this.editDialog = true;
    },
    async refresh() {
      try {
        this.loading = true;
        const resp = await this.$api.get(baseURL);
        this.loading = false;

        this.items = resp.data.groups.map((group) => {
          for (const widget of group.widgets) {
            if (group[widget.type] === undefined) group[widget.type] = {};
            group[widget.type] = Object.assign(group[widget.type], widget.data);
            group[widget.type].id = widget.id;
            group[widget.type].type = widget.type;
          }
          return group;
        });
      } catch (error) {
        console.error("Error load widget groups list: ", error);
      }

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.code {
  border: solid 1px #ccc;
  padding: 10px;
  font: normal 12px "Courier New";
  word-break: break-all;
}
</style>
