<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("account.account") }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.name" outlined dense hide-details :label="$t('name')" />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.code" outlined dense hide-details :label="$t('account.code')" readonly />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-select
              v-model="item.langs"
              multiple
              outlined
              dense
              hide-details
              :label="$t('account.languages')"
              :items="langs"
              item-text="name"
              item-value="id"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field v-model="item.website" outlined dense hide-details :label="$t('account.website')" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4">
            <v-row>
              <v-col cols="4">
                <v-img v-if="item.logo" :src="item.logo" max-height="100" contain />
              </v-col>
              <v-col cols="8">
                <v-file-input v-model="logo" outlined dense hide-details :label="$t('account.logo')" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" small @click="save">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import BaseView from "../../BaseView.vue";

const baseURL = "/account/profile";

export default {
  name: "SettingsAccount",
  mixins: [BaseView],
  data() {
    return {
      item: {},
      logo: null,
      langs: [
        { id: "ru", name: "Русский" },
        { id: "en", name: "English" },
        { id: "uk", name: "Українська" },
        { id: "de", name: "German" },
      ],
    };
  },
  async created() {
    this.setTitle(this.$t("account.account"));
    this.checkPermission();

    const resp = await this.$api.get(baseURL);
    if (resp.success) {
      this.item = resp.data;
    }
  },
  methods: {
    async save() {
      let item = { ...this.item };
      delete item.logo;

      const resp = await this.$api.patch(baseURL, item);
      if (resp.success) {
        this.item = resp.data;
        this.showSnackbar("success", this.$t("saved"));
        await this.uploadLogo();
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
    async uploadLogo() {
      if (!this.logo) return;

      const data = new FormData();
      data.append("logo", this.logo);

      const resp = await this.$api.patch(baseURL, data);
      if (resp.success) {
        this.item = resp.data;
      }
    },
  },
};
</script>
