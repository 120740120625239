<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("tickets.boards") }}
        <v-spacer />
        <v-btn small color="primary" @click="add">{{ $t("tickets.add-board") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="boards"
          :loading="loading"
          :mobile-breakpoint="0"
          hide-default-footer
          class="elevation-1"
        >
          <template #item.actions="{ item }">
            <v-btn small color="primary" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
          <template #item.type="{ item }">
            {{ types[item.type] }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("tickets.board") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="item.name" outlined dense :label="$t('name')" required />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="item.type"
                outlined
                dense
                :label="$t('type')"
                :items="typesList"
                item-value="id"
                item-text="name"
              />
            </v-col>
          </v-row>
          <h4 class="mb-1 black--text">{{ $t("tickets.statuses") }}</h4>
          <v-row v-for="status in item.statuses" :key="status.id">
            <v-col cols="6">
              <v-text-field v-model="status.name" outlined dense hide-details :label="$t('tickets.status')" required />
            </v-col>
            <v-col cols="2">
              <v-text-field
                v-model="status.order_num"
                outlined
                dense
                hide-details
                :label="$t('general.order')"
                @change="reOrderStatuses"
              />
            </v-col>
          </v-row>
          <v-btn x-small color="info" @click="addStatus" class="mt-1">{{ $t("add") }}</v-btn>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BaseView from "@/components/BaseView.vue";

export default {
  name: "TicketsBoards",
  mixins: [BaseView],
  data() {
    return {
      perm: "admin",
      editDialog: false,
      headers: [
        { text: this.$t("name"), value: "name" },
        { text: this.$t("tickets.type"), value: "type", align: "center" },
        { text: "", value: "actions", align: "end" },
      ],
      items: [],
      item: { statuses: [] },
      loading: false,
      types: {
        l: this.$t("tickets.list"),
        c: this.$t("tickets.board"),
      },
    };
  },
  computed: {
    ...mapState(["boards"]),
    typesList() {
      let types = [];
      for (const [key, value] of Object.entries(this.types)) {
        types.push({ id: key, name: value });
      }
      return types;
    },
  },
  async created() {
    this.setTitle(this.$t("tickets.boards"));
    this.checkPermission();
  },
  methods: {
    add() {
      const statuses = [];
      let order = 10;
      for (const name of ["n", "p", "c"]) {
        statuses.push({ name: this.$t("tickets.status-" + name), order_num: order });
        order += 10;
      }
      this.edit({ name: "", type: "l", statuses });
    },
    addStatus() {
      this.item.statuses.push({ name: "", order_num: 10 });
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.errors = {};
      this.editDialog = true;
    },
    reOrderStatuses() {
      this.item.statuses.sort((a, b) => (a.order_num > b.order_num ? 1 : -1));
    },
    async save() {
      const resp = await this.$api.save("/tickets/boards", this.item);
      if (resp.success) {
        this.editDialog = false;
        this.showSnackbar("success", this.$t("saved"));

        // update board data in store
        const boards = this.boards,
          updatedBoard = resp.data;
        let board = boards.find((b) => b.id === updatedBoard.id);
        if (board) {
          Object.assign(board, updatedBoard);
        } else {
          boards.push(board);
        }
        this.$store.commit("setBoards", boards);
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
  },
};
</script>
