<template>
  <v-card>
    <v-card-title class="pa-4">
      {{ $t("widgets.chat-settings") }}
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="pa-4">
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="widget.title" dense outlined hide-details :label="$t('widgets.chat-title')" />
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="widget.left" dense outlined hide-details :label="$t('widgets.margin-left')" />
        </v-col>
        <v-col cols="4">
          <v-menu v-model="colorPicker" :close-on-content-click="false" offset-y min-width="290px">
            <template #activator="{ on }">
              <v-text-field
                v-model="color.hex"
                outlined
                dense
                hide-details
                :label="$t('widgets.support-button-color')"
                readonly
                :style="{ backgroundColor: color ? color.hex : '' }"
                v-on="on"
              />
            </template>
            <v-color-picker v-model="color" hide-inputs mode="rgba" />
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-text-field v-model="widget.right" dense outlined hide-details :label="$t('widgets.margin-right')" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4" offset="4">
          <v-text-field v-model="widget.bottom" dense outlined hide-details :label="$t('widgets.margin-bottom')" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import BaseView from "@/components/BaseView.vue";

export default {
  name: "ChatSettings",
  mixins: [BaseView],
  created() {
    Object.assign(this.widget, this.value);
    if (this.widget.color) {
      this.color = { hex: this.widget.color || "#2F80ED" };
    }
  },
  data() {
    return {
      color: { hex: "#2F80ED" },
      colorPicker: null,
      widget: {
        title: this.$t("widgets.chat-title-value"),
        color: "",
        left: "",
        right: 20,
        bottom: 20,
      },
    };
  },
  methods: {
    save() {
      this.widget.color = this.color.hex;
      this.$emit("input", this.widget);
      this.$emit("close");
    },
  },
  props: {
    channels: Array,
    value: Object,
  },
  watch: {
    value() {
      Object.assign(this.widget, this.value);
      if (this.widget.left) this.widget.left = parseInt(this.widget.left);
      if (this.widget.right) this.widget.right = parseInt(this.widget.right);
      if (this.widget.bottom) this.widget.bottom = parseInt(this.widget.bottom);
      if (this.widget.color) {
        this.color = { hex: this.widget.color || "#2F80ED" };
      }
    },
  },
};
</script>
