import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// routes
import Login from "./components/account/Login";
import Register from "./components/account/Register";
import ChangePassword from "./components/account/ChangePassword";

import Calls from "./components/calls/Calls";

import Clients from "./components/clients/ClientsList";

import Chat from "./components/chats/Chat";
import Channels from "./components/chats/settings/Channels.vue";
import Templates from "./components/chats/settings/Templates.vue";

import Board from "./components/tickets/Board";
import TicketsBoards from "./components/tickets/settings/Boards.vue";

import KnowledgeBase from "./components/kb/KnowledgeBase";
import Page from "./components/kb/Page";

import VisitorsList from "@/components/visitors/VisitorsList.vue";

import Mail from "./components/mail/Mail";
import Mailboxes from "./components/mail/settings/Mailboxes";

// Settings
import SettingsBase from "./components/layout/SettingsBase";
import Account from "./components/account/settings/Account.vue";
import Users from "./components/users/Users";
import UserGroups from "./components/users/Groups.vue";
import Profile from "./components/profile/Profile";
import Triggers from "./components/triggers/TriggersList";
import WidgetGroup from "./components/widgets/WidgetGroup";
import WidgetGroups from "./components/widgets/WidgetGroups";

const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", redirect: "/chats", name: "index" },

    { path: "/login", component: Login, name: "login", meta: { layout: "blank" } },
    { path: "/register", component: Register, name: "register", meta: { layout: "blank" } },
    { path: "/change-password", component: ChangePassword, name: "change-password" },

    { path: "/calls", component: Calls, name: "calls" },
    { path: "/clients", component: Clients, name: "clients" },
    { path: "/chats", component: Chat, name: "chats" },
    { path: "/chats/:id", component: Chat, name: "chat-messages" },
    { path: "/knowledge-base/:kb", component: KnowledgeBase, name: "kb-pages" },
    { path: "/knowledge-base/:kb/:id", component: Page, name: "kb-page-edit" },
    { path: "/mail", component: Mail, name: "mail" },
    { path: "/board/:id", component: Board, name: "board" },
    { path: "/visitors", component: VisitorsList, name: "visitors" },

    {
      path: "/settings",
      component: SettingsBase,
      children: [
        { path: "account", component: Account, name: "settings-account" },
        { path: "boards", component: TicketsBoards, name: "settings-tickets-boards" },
        { path: "channels", component: Channels, name: "settings-channels" },
        { path: "templates", component: Templates, name: "settings-templates" },
        { path: "mail", component: Mailboxes, name: "settings-mail" },
        { path: "profile", component: Profile, name: "settings-profile" },
        { path: "users", component: Users, name: "settings-users" },
        { path: "user-groups", component: UserGroups, name: "settings-user-groups" },
        { path: "triggers", component: Triggers, name: "settings-triggers" },
        { path: "widget-groups", component: WidgetGroups, name: "settings-widget-groups" },
        { path: "widget-groups/:id", component: WidgetGroup, name: "settings-widget-group-edit" },
      ],
    },
  ],
});

export default router;
