<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("mail.accounts") }}
        <div class="ml-10">
          <v-btn v-show="hosts.length > 0" small color="success" @click="add"
            ><v-icon small>mdi-plus</v-icon>{{ $t("add") }}</v-btn
          >
        </div>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :mobile-breakpoint="0"
          hide-default-footer
          class="elevation-1"
        >
          <template #item.active="{ item }">
            <v-icon v-if="item.active" color="green">mdi-check-circle</v-icon>
            <v-icon v-else color="red">mdi-alert-circle</v-icon>
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="primary" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
          <template #item.type="{ item }">
            {{ types[item.type] }}
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("board") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="item.username" outlined dense :label="$t('username')" required />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="item.host_id"
                outlined
                dense
                :label="$t('host')"
                :items="hosts"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="item.type"
                outlined
                dense
                :label="$t('type')"
                :items="typesList"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col cols="6">
              <div v-if="item.type === 't'">
                <v-select
                  v-model="item.referrer_id"
                  outlined
                  dense
                  :label="$t('tickets.board-status')"
                  :items="boardsStatuses"
                />
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "../../BaseView.vue";

const baseURL = "/mail/mailboxes";

export default {
  name: "MailboxesList",
  mixins: [BaseView],
  data() {
    return {
      perm: "admin",
      editDialog: false,
      headers: [
        { text: this.$t("E-mail"), value: "email" },
        { text: this.$t("type"), value: "type", align: "center" },
        { text: this.$t("status"), value: "active", align: "center" },
        { text: "", value: "actions", align: "end" },
      ],
      hosts: [],
      items: [],
      item: {},
      loading: false,
      types: {
        p: this.$t("mail.personal"),
        t: this.$t("mail.tickets"),
      },
    };
  },
  computed: {
    boardsStatuses() {
      let statuses = [];

      for (let board of this.$store.state.boards) {
        statuses.push({ header: board.name });

        for (let status of board.statuses) {
          statuses.push({
            value: status.id.toString(),
            text: status.name,
          });
        }

        statuses.push({ divider: true });
      }

      return statuses;
    },
    typesList() {
      let types = [];
      for (const [key, value] of Object.entries(this.types)) {
        types.push({ id: key, name: value });
      }
      return types;
    },
  },
  async created() {
    this.setTitle(this.$t("mail.accounts"));
    this.checkPermission();
    await this.loadHosts();
    await this.refresh();
  },
  methods: {
    add() {
      this.edit({ perms: {} });
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.errors = {};
      this.editDialog = true;
    },
    async loadHosts() {
      try {
        const resp = await this.$api.get("hosts");

        this.hosts = [];
        for (let host of resp.data.results) {
          if (host.mail === "a") {
            this.hosts.push(host);
          }
        }

        if (this.hosts.length === 0) {
          console.log("No hosts with active mail record");
        }
      } catch (err) {
        console.error("Error loading hosts: ", err);
      }
    },
    async save() {
      if (this.item.id) {
        try {
          let url = baseURL + "/" + this.item.id;
          const resp = await this.$api.patch(url, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          const resp = await this.$api.post(baseURL, this.item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
    async refresh() {
      this.loading = true;

      try {
        const resp = await this.$api.get(baseURL);
        this.items = resp.data;
      } catch (error) {
        console.error("Error loading mail boxes: ", error);
      }

      this.loading = false;
    },
  },
};
</script>
