<template>
  <v-container fluid>
    <v-card>
      <v-card-title>{{ $t("widgets.site") }} {{ item.hosts.join(", ") }} (API key: {{ item.key }})</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <label class="black--text">{{ $t("widgets.copy-code") }} &lt;/body&gt;</label>
            <div class="code mb-4">
              &lt;script src="https://cdn.supportix.net/widgets.js" async defer data-key="{{
                item.key
              }}"&gt;&lt;/script&gt;
            </div>

            <h3 class="black--text mt-3 mb-2">{{ $t("widgets.domains") }}</h3>
            <v-text-field v-model="host" multiple dense outlined hide-details @blur="addHost" @keyup.enter="addHost">
              <template v-slot:prepend-inner>
                <v-chip
                  v-for="(host, idx) in item.hosts"
                  :key="idx"
                  close
                  class="mr-1"
                  color="primary"
                  small
                  @click:close="removeHostIdx(idx)"
                >
                  {{ host }}
                </v-chip>
              </template>
            </v-text-field>
            <div class="caption">{{ $t("example") }}: www.site.com, *.site.net</div>

            <h3 class="black--text mt-3 mb-2">{{ $t("widgets.configuration") }}</h3>
            <v-card class="mx-auto elevation-2">
              <v-list nav>
                <v-list-item-group v-model="selected">
                  <template v-for="(widget, i) in widgets">
                    <v-list-item
                      :key="widget.id"
                      :disabled="widget.disabled"
                      link
                      @click="editWidget(widget)"
                      class="mb-0"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{ $t("widgets.widget-" + widget.type) }}</v-list-item-title>
                        <v-list-item-subtitle>{{ getWidgetDescription(widget) }}</v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-switch dense hide-details v-model="widget.active" class="mt-0" />
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider :key="i" v-if="i < widgets.length - 1" />
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <iframe id="preview" ref="preview" frameborder="0" height="500" width="100%" :src="previewUrl" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pa-4">
        <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        <v-btn outlined color="primary" :to="{ name: 'settings-widget-groups' }" class="ml-4">{{ $t("cancel") }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="widgetForm" max-width="600">
      <ChatSettings v-if="widget && widget.type === 'chat'" v-model="widget.data" @close="closeWidgetForm" />
      <SupportButtonSettings
        v-if="widget && widget.type === 'support'"
        v-model="widget.data"
        :channels="channels"
        @close="closeWidgetForm"
      />
      <SurveySettings v-if="widget && widget.type === 'survey'" v-model="widget.data" @close="closeWidgetForm" />
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "../BaseView.vue";
import ChatSettings from "./ChatSettings.vue";
import SupportButtonSettings from "./SupportButtonSettings.vue";
import SurveySettings from "./SurveySettings.vue";

const baseURL = "/widgets/groups";
const WIDGET_CHAT = "chat",
  WIDGET_SUPPORT = "support",
  WIDGET_SURVEY = "survey";

export default {
  name: "SettingsWidgets",
  components: { ChatSettings, SupportButtonSettings, SurveySettings },
  mixins: [BaseView],
  data() {
    return {
      perm: "widgets",
      channels: [],
      channelsMap: {},
      supportColor: {},
      colorPicker: null,
      host: "",
      item: { hosts: [] },
      selected: null,
      widget: { type: null },
      widgets: [],
      widgetForm: false,
      loading: false,
      tab: 0,
    };
  },
  computed: {
    previewUrl() {
      return "/api/widgets/groups/" + this.item.key + "/preview";
    },
  },
  async created() {
    this.setTitle(this.$t("widgets.widgets"));
    this.checkPermission();

    this.widgets = [this.getInitialWidget(WIDGET_SUPPORT), this.getInitialWidget(WIDGET_SURVEY)];
    await this.loadData();
  },
  methods: {
    addHost() {
      const host = this.host.trim();
      if (host) {
        this.item.hosts.push(host);
      }
    },
    removeHostIdx(index) {
      this.item.hosts.splice(index, 1);
    },
    closeWidgetForm() {
      this.widgetForm = false;
      this.reloadPreview();
    },
    getInitialWidget(type) {
      return { type, data: {}, active: false, disabled: false };
    },
    getWidgetDescription(widget) {
      let description = "";
      switch (widget.type) {
        case WIDGET_SUPPORT: {
          const channels = widget.data && widget.data.channels ? widget.data.channels.map((c) => c.name) : [];
          description = channels.length > 0 ? channels.join(", ") : this.$t("not-configured");
          break;
        }

        default: {
          description = this.$t("widgets.widget-" + widget.type + "-description");
          break;
        }
      }
      return description;
    },
    editWidget(widget) {
      if (!widget.active) return;
      this.widget = widget;
      this.widgetForm = true;
    },
    async loadData() {
      await Promise.all([this.loadWidgetGroup(), this.loadChannels()]);
    },
    processGroup(group) {
      group.hosts = group.hosts.split(",").map((h) => h.trim());
      this.item = group;

      for (const itemWidget of this.item.widgets) {
        let widget = this.widgets.find((w) => w.type === itemWidget.type);
        if (widget) {
          widget.data = itemWidget.data;
          widget.active = itemWidget.active;
          widget.disabled = false;
        } else {
          widget = this.getInitialWidget(itemWidget.type);
          this.widgets.push(widget);
        }

        if (itemWidget.type === WIDGET_SUPPORT && itemWidget.data.channels) {
          const chatChannel = itemWidget.data.channels.find((c) => c.type === "ch");
          if (chatChannel) {
            const chat = this.getInitialWidget("chat");
            chat.disabled = false;
            chat.active = true;

            if (itemWidget.data.chat) {
              Object.assign(chat.data, itemWidget.data.chat);
            }
            this.widgets.push(chat);
          }
        }
      }
    },
    async loadWidgetGroup() {
      if (this.$route.params.id === "new") return;
      try {
        this.loading = true;
        const resp = await this.$api.get(baseURL + "/" + this.$route.params.id);
        this.loading = false;

        this.processGroup(resp.data);
      } catch (error) {
        console.error("Error load widget group info: ", error);
      }

      this.loading = false;
    },
    async loadChannels() {
      try {
        const resp = await this.$api.get("chat/channels");

        this.channels = [];
        this.channelsMap = {};
        for (const channel of resp.data.channels) {
          this.channels.push(channel);
          this.channelsMap[channel.id] = channel;
        }
      } catch (error) {
        console.error("Error load channels list: ", error);
      }
    },
    reloadPreview() {
      const config = {};
      for (const widget of this.widgets) {
        config[widget.type] = widget.data;
      }

      const iframe = document.getElementById("preview");
      if (iframe && iframe.contentWindow && iframe.contentWindow.supportixWidgetsAPI) {
        iframe.contentWindow.supportixWidgetsAPI.setConfig(config);
      }
      // this.$refs.preview.contentWindow.location.reload();
    },
    async save() {
      const group = {
        id: this.item.id,
        hosts: this.item.hosts.join(","),
        widgets: [],
      };

      // reformat config
      for (const widget of this.widgets) {
        if (widget.disabled) continue;

        if (widget.type === WIDGET_CHAT) continue;

        if (widget.type === WIDGET_SUPPORT) {
          for (const n in widget.data.channels) {
            const chId = widget.data.channels[n].id;
            widget.data.channels[n].type = this.channelsMap[chId].type;
            widget.data.channels[n].url = this.channelsMap[chId].info?.uri;

            if (widget.data.channels[n].type === "ch") {
              widget.data.chat = { ...this.chat.data };
            }
          }
        }

        group.widgets.push(widget);
      }

      // save data
      const resp = await this.$api.save(baseURL, group);
      if (resp.success) {
        this.item = resp.data;
        this.showSnackbar("success", this.$t("saved"));
        this.reloadPreview();
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
  },
};
</script>

<style scoped lang="scss">
.code {
  border: solid 1px #ccc;
  border-radius: 5px;
  padding: 10px;
  color: #000;
  background: #dddddd;
  font-family: "Courier New", serif;
  font-size: 14px;
  word-break: break-word !important;
}
</style>
