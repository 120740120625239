<template>
  <v-container fluid>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-card>
          <v-card-title>
            {{ $t("changing-password") }}
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="old_password"
              outlined
              type="password"
              label="Текущий пароль"
              required
            ></v-text-field>
            <v-text-field v-model="new_password1" outlined type="password" label="Новый пароль" required></v-text-field>
            <v-text-field v-model="new_password1" outlined type="password" label="Новый пароль" required></v-text-field>
            <div class="text-center">
              <v-btn class="mx-2" dark color="primary" @click="changePassword">{{ $t("change-password") }}</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChangePassword",
  data: () => ({
    old_password: "",
    new_password1: "",
    new_password2: "",
  }),
  methods: {
    changePassword() {},
  },
};
</script>
