class WS {
  constructor(vue) {
    this.vue = vue;
    this.socket = null;
    this.onMessageHandler = null;
  }

  async connect(url, token, messageHandler) {
    if (url) {
      this.url = url;
    }
    if (token) {
      this.token = token;
    }
    if (messageHandler) {
      this.onMessageHandler = messageHandler;
    }

    this.socket = new WebSocket(this.url);

    const that = this;
    this.socket.onopen = async () => {
      await this.sendEvent("chat.token", { token: this.token });
      await this.sendEvent("chat.threads");
    };

    this.socket.onclose = async (event) => {
      if (event.code !== 1000) {
        console.error("Close websocket with error", event);

        const that = this;
        // reconnect on bad close code
        setTimeout(async () => {
          await that.connect();
        }, 1000);
      }
    };

    this.socket.onmessage = (event) => {
      if (that.onMessageHandler) {
        that.onMessageHandler(event.data);
      } else {
        console.error("No message handler for event: ", event);
      }
    };

    this.socket.onerror = (event) => {
      console.error("WS socket error", event);
    };
  }

  isConnected() {
    return this.socket.readyState === 1;
  }

  async close() {
    if (this.socket) {
      await this.socket.close(1000);
      this.socket = null;
    }
  }

  async sendEvent(name, data) {
    data = data || {};
    data.event = name;
    await this.send(data);
  }

  async send(data) {
    const msg = JSON.stringify(data);
    if (this.socket && this.socket.readyState === 1) {
      console.log("Sending event", msg);
      await this.socket.send(msg);
    } else {
      console.log("Socket is not ready", this.socket?.readyState);
    }
  }
}

export default {
  install(Vue) {
    Vue.prototype.$ws = new WS();
  },
};
