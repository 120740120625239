<template>
  <v-container fluid class="fill-height form-bg">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="4" class="pa-6">
        <v-img src="@/assets/images/logo.svg" width="120" class="mb-5" />
        <div class="title mb-4">{{ $t("auth.welcome") }}</div>
        <v-form>
          <v-card class="elevation-6 mb-12">
            <v-toolbar flat>
              <v-spacer />
              <v-toolbar-title>{{ $t("auth.title") }}</v-toolbar-title>
              <v-spacer />
            </v-toolbar>
            <v-card-text>
              <v-alert v-if="error" type="error">{{ $t("auth.error") }}</v-alert>
              <v-text-field
                v-model="username"
                dense
                outlined
                hide-details
                :label="$t('auth.email')"
                prepend-icon="mdi-email"
                type="email"
                required
                class="mb-2"
              />
              <v-text-field
                v-model="password"
                dense
                outlined
                hide-details
                :label="$t('auth.password')"
                prepend-icon="mdi-lock"
                type="password"
                required
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn class="px-6 mb-3" color="success" @click="signIn">{{ $t("auth.sign-in") }}</v-btn>
              <v-spacer />
            </v-card-actions>
          </v-card>
        </v-form>
        <div class="subtitle mb-6">
          {{ $t("auth.not-registered") }}?
          <router-link to="register">{{ $t("auth.register") }}</router-link>
        </div>
        <div class="title mb-2 mt-6">{{ $t("auth.support") }}</div>
        <div class="subtitle my-2">{{ $t("email") }}: <a href="mailto:info@supportix.co">info@supportix.co</a></div>
        <div class="subtitle my-2">
          {{ $t("telegram") }}: <a href="https://t.me/OurSupportixBot">@OurSupportixBot</a>
        </div>
      </v-col>
      <v-col cols="12" sm="8" class="pa-0 d-none d-md-block">
        <div class="text-center">
          <img src="https://supportix.co/images/main-page.svg" width="500" alt="" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import BaseView from "@/components/BaseView";

export default {
  name: "UserLogin",
  mixins: [BaseView],
  computed: {
    ...mapState(["mobile"]),
  },
  created() {
    this.setTitle(this.$t("auth.title"));
  },
  data: () => ({
    error: false,
    username: "",
    password: "",
  }),
  methods: {
    async signIn() {
      this.error = false;
      this.$api.setToken("");

      const data = { email: this.username, username: this.username, password: this.password };
      try {
        const resp = await this.$api.post("account/sign-in", data);
        if (resp.success && resp.data.token) {
          this.$api.saveToken(resp.data.token);
          this.$store.commit("setUser", resp.data.user);
          this.$store.commit("setPushToken", resp.data.has_push_token);

          this.$router.push({ name: "chats" });
        } else {
          this.error = true;
        }
      } catch (err) {
        this.error = true;
        console.error("Error login:", err);
      }
    },
  },
};
</script>

<style scoped>
.form-bg {
  background-color: #f5f5ff;
}
.img {
  height: 100vh;
}
</style>
