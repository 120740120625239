<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("chats.channels") }}
        <v-spacer />
        <v-btn small color="primary" @click="add">{{ $t("chats.add-channel") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" class="elevation-1">
          <template #item.group="{ item }">
            {{ (getAccountGroup(item.default_group_id) || {}).name }}
          </template>
          <template #item.type="{ item }">
            <v-img :src="getChannelIcon(item.type)" width="32" />
          </template>
          <template #item.status="{ item }">
            <v-chip v-if="item.status === 'n'" x-small color="primary">{{ $t("chats.connecting") }}</v-chip>
            <v-chip v-else-if="item.status === 'c'" x-small color="success">{{ $t("chats.connected") }}</v-chip>
            <v-chip v-else-if="item.status === 'e'" x-small color="error">{{ $t("chats.error") }}</v-chip>
            <v-chip v-else-if="item.status === 'd'" x-small>{{ $t("chats.disabled") }}</v-chip>
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="info" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="800px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("chats.channel") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-0">
          <v-row>
            <v-col v-if="!item.id" cols="4">
              <v-list nav dense>
                <v-list-item-group v-model="selected">
                  <v-list-item v-for="type in types" :key="type.id" @click="item.type = type.id">
                    <div style="width: 32px">
                      <v-img :src="getChannelIcon(type.id)" />
                    </div>
                    <v-list-item-content class="pl-2">
                      <v-list-item-title>{{ type.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
            <v-col>
              <div class="pa-3">
                <div v-if="!item.id">
                  <div v-show="item.type === 'tg'" v-html="$t('chats.connect-telegram')" />
                  <div v-show="item.type === 'fb'" v-html="$t('chats.connect-facebook')" />
                  <div v-show="item.type === 'vb'" v-html="$t('chats.connect-viber')" />
                  <v-text-field
                    v-show="item.type === 'tg' || item.type === 'vb'"
                    v-model="item.token"
                    outlined
                    dense
                    hide-details="auto"
                    :label="$t('chats.token')"
                    :readonly="!!item.id"
                    class="my-4"
                  />
                  <v-row v-if="item.type === 'em'">
                    <v-col>
                      <v-text-field
                        v-model="username"
                        outlined
                        dense
                        hide-details
                        :label="$t('mail.username')"
                        class="my-4"
                      />
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="password"
                        outlined
                        dense
                        hide-details
                        type="password"
                        :label="$t('mail.password')"
                        class="my-4"
                      />
                    </v-col>
                  </v-row>
                </div>
                <v-row>
                  <v-col cols="12">
                    <div class="mb-1">{{ $t("chats.choose-channel-name") }}</div>
                    <v-text-field v-model="item.name" outlined dense hide-details="auto" :label="$t('name')" />
                  </v-col>
                  <v-col>
                    <div class="mb-1">{{ $t("chats.choose-default-group") }}</div>
                    <v-select
                      v-model="item.default_group_id"
                      outlined
                      dense
                      hide-details
                      :label="$t('chats.group')"
                      item-text="name"
                      item-value="id"
                      :items="accountGroups"
                    />
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseView from "../../BaseView.vue";

const baseURL = "/chat/channels";

export default {
  name: "SettingsChannels",
  mixins: [BaseView],
  computed: {
    ...mapGetters(["getAccountGroup"]),
    ...mapState(["accountGroups"]),
  },
  async created() {
    this.setTitle(this.$t("chats.channels"));
    this.checkPermission();
    await this.refresh();
  },
  data() {
    return {
      perm: "channels",
      editDialog: false,
      headers: [
        { text: "", value: "type", width: 58, sortable: false },
        { text: this.$t("name"), value: "name", width: "20%", sortable: false },
        { text: this.$t("status"), value: "status", width: 100, align: "center", sortable: false },
        { text: this.$t("chats.group"), value: "group", align: "center", sortable: false },
        { text: "", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      items: [],
      item: { type: "tg" },
      selected: 0,
      username: "",
      password: "",
      types: [
        { id: "tg", name: "Telegram" },
        { id: "fb", name: "Facebook Messenger" },
        { id: "vb", name: "Viber" },
        { id: "em", name: "E-mail" },
      ],
    };
  },
  methods: {
    add() {
      this.item = { type: "tg", name: "", default_group_id: null };
      this.editDialog = true;
    },
    edit(item) {
      this.item = item;
      this.editDialog = true;
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = {};
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    async save() {
      const item = { ...this.item };
      if (item.type === "em") {
        item.token = this.username + ":" + this.password;
      }

      if (item.id) {
        try {
          let url = baseURL + "/" + item.id;
          const resp = await this.$api.patch(url, item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          const resp = await this.$api.post(baseURL, item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
    async refresh() {
      try {
        const resp = await this.$api.get(baseURL);
        this.items = resp.data.channels;
      } catch (err) {
        console.error("Error load channels list: ", err);
      }
    },
  },
};
</script>
