import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    account: { name: "" },
    accountGroups: [],
    boards: [],
    knowledgeBases: [],
    mobile: false,
    snackbar: {},
    threads: [],
    threadsIds: {},
    templates: [],
    tick: 0,
    ticket: {},
    user: { id: null, perms: {} },
    users: [],
    hasPushToken: false,
    widgetGroups: [],
  },
  getters: {
    getBoard: (state) => (id) => {
      return state.boards.find((board) => board.id === id) || null;
    },
    getAccountGroup: (state) => (id) => {
      return state.accountGroups.find((group) => group.id === id) || null;
    },
    getUnreadMessages: (state) => {
      let count = 0;
      for (const thread of state.threads) {
        count += thread.unread_messages || 0;
      }
      return count;
    },
    getThread: (state) => (id) => {
      return state.threads.find((t) => t.id === id);
    },
    getUser: (state) => (id) => {
      return state.users.find((u) => u.id === id);
    },
  },
  mutations: {
    tick(state) {
      state.tick++;
    },
    setAccount(state, data) {
      state.account = data;
    },
    setMobile(state, mobile) {
      state.mobile = mobile;
    },
    setPushToken(state, value) {
      state.hasPushToken = value;
    },
    handleWSMessage(state, data) {
      console.log("Receive message", data);
      switch (data.event) {
        case "chat.event":
          switch (data.name) {
            case "start_typing":
              this.commit("updateThreadField", { id: data.thread_id, typing: true });
              break;

            case "stop_typing":
              this.commit("updateThreadField", { id: data.thread_id, typing: false });
              break;
          }
          break;

        case "chat.message":
          delete data.event;
          delete data.success;
          this.commit("updateThreadMessage", data);
          break;

        case "chat.messages":
          for (const message of data.messages) {
            this.commit("updateThreadMessage", message);
          }
          break;

        case "chat.thread":
          delete data.event;
          delete data.success;
          this.commit("updateThread", data);
          break;

        case "chat.threads":
          for (const thread of data.threads) {
            this.commit("updateThread", thread);
          }
          break;

        case "chat.token":
          if (!data.success) {
            console.error("Logout");
          }
          break;

        default:
          console.error("Unknown event type", data);
          break;
      }
    },
    setBoards(state, boards) {
      state.boards = boards;
    },
    setAccountGroups(state, groups) {
      state.accountGroups = groups;
    },
    setKnowledgeBases(state, kb) {
      state.knowledgeBases = kb;
    },
    setSnackbar(state, snackbar) {
      state.snackbar = snackbar;
    },
    setTicket(state, ticket) {
      state.ticket = ticket;
    },
    setTemplates(state, templates) {
      state.templates = templates;
    },
    addUser(state, user) {
      state.users.push(user);
    },
    setUsers(state, users) {
      state.users = users;
    },
    setUser(state, user) {
      state.user = user;
    },
    setWidgetGroups(state, groups) {
      state.widgetGroups = groups;
    },
    updateThread(state, thread) {
      const threads = [...state.threads];

      let existsThread = threads.find((item) => item.id === thread.id);
      if (existsThread === undefined) {
        thread.counter = 0;
        thread.messages = [];
        thread.text = "";
        thread.typing = false;

        threads.push(thread);
      } else {
        const fields = [
          "name",
          "conversation",
          "unread_messages",
          "client",
          "client_id",
          "group",
          "group_id",
          "user",
          "user_id",
          "update_time",
          "last_message",
        ];
        for (const field of fields) {
          if (thread[field] !== undefined) {
            existsThread[field] = thread[field];
          }
        }
        if (thread.text !== undefined) {
          existsThread.text = thread.text;
        }
      }

      // sorting threads
      threads.sort(function (a, b) {
        if (a.update_time < b.update_time) return 1; // desc
        if (a.update_time > b.update_time) return -1; // desc
        return 0;
      });
      state.threads = threads;
    },
    updateThreadField(state, payload) {
      let thread = state.threads.find((item) => item.id === payload.id);
      if (thread) {
        Object.assign(thread, payload);
      }
    },
    updateThreadMessage(state, message) {
      let threadIndex = null;
      for (const i in state.threads) {
        if (state.threads[i].id === message.thread_id) {
          threadIndex = i;
        }
      }

      if (threadIndex === null) {
        state.threads.push({ id: message.thread_id, name: "", messages: [] });
        threadIndex = state.threads.length - 1;
      }

      const thread = state.threads[threadIndex];
      if (thread.update_time < message.created) {
        thread.last_message = message.content;
      }

      let existMessage = thread.messages.find((m) => m.id === message.id);
      if (existMessage === undefined) {
        state.threads[threadIndex].counter++;
        state.threads[threadIndex].messages.push(message);
      } else {
        existMessage.type = message.type;
        existMessage.content = message.content;
      }
    },
  },
});
