<script>
export default {
  name: "LoadDataMixin",
  methods: {
    async loadAccountInfo() {
      if (this.account.id === undefined) {
        try {
          const resp = await this.$api.get("account/profile");
          this.$store.commit("setAccount", resp.data);
        } catch (err) {
          console.error("Error loading account info", err);
        }
      }
    },
    async loadBoards() {
      try {
        const resp = await this.$api.get("boards");
        this.$store.commit("setBoards", resp.data.boards);
      } catch (err) {
        console.error("Error loading ticket boards", err);
      }
    },
    async loadAccountGroups() {
      try {
        const resp = await this.$api.get("account/groups");
        this.$store.commit("setAccountGroups", resp.data.groups);
      } catch (err) {
        console.error("Error loading account groups", err);
      }
    },
    async loadKnowledgeBases() {
      try {
        const resp = await this.$api.get("kb");
        this.$store.commit("setKnowledgeBases", resp.data.knowledge_bases);
      } catch (err) {
        console.error("Error loading knowledge bases", err);
      }
    },
    async loadUsers() {
      try {
        const resp = await this.$api.get("account/users");
        this.$store.commit("setUsers", resp.data.users);
      } catch (err) {
        console.error("Error loading users", err);
      }
    },
    async loadWidgetGroups() {
      try {
        const resp = await this.$api.get("widgets/groups");
        this.$store.commit("setWidgetGroups", resp.data.groups);
      } catch (err) {
        console.error("Error loading widget groups", err);
      }
    },
    async loadData() {
      await Promise.all([
        this.loadAccountInfo(),
        // this.loadBoards(),
        this.loadAccountGroups(),
        this.loadKnowledgeBases(),
        this.loadUsers(),
        this.loadWidgetGroups(),
      ]);
    },
  },
};
</script>
