<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="primary" dark flat dense>
        <v-toolbar-title>{{ kb.name }} &rarr; {{ $t("kb.page") }} &laquo;{{ item.name }}&raquo;</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-3">
        <v-row class="mb-1">
          <v-col lg="4">
            <v-text-field
              v-model="item.name"
              dense
              outlined
              hide-details
              :label="$t('kb.title')"
              @keyup="generateSlug"
            />
          </v-col>
          <v-col lg="2">
            <v-switch
              v-model="allowSlugGeneration"
              class="mt-1"
              dense
              outlined
              hide-details
              :label="$t('kb.slug-generation')"
            />
          </v-col>
          <v-col lg="4">
            <v-text-field v-model="item.slug" dense outlined hide-details :label="$t('kb.slug')" />
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col lg="4">
            <v-select
              v-model="item.category_id"
              dense
              outlined
              hide-details
              :label="$t('kb.category')"
              :items="categories"
              item-value="id"
              item-text="name"
            />
          </v-col>
          <v-col>
            <div class="pt-2">URL: {{ url }}</div>
          </v-col>
        </v-row>
        <VueEditor v-model="item.content" />
      </v-card-text>
      <v-card-actions>
        <v-btn small :to="{ name: 'kb-pages', params: { kb: this.$route.params.kb } }">{{ $t("cancel") }}</v-btn>
        <v-spacer />
        <v-btn small color="success" @click="save">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { VueEditor } from "vue2-editor";

import BaseView from "../BaseView";
import KnowledgeBaseMixin from "./KnowledgeBaseMixin.vue";

export default {
  name: "PageEdit",
  components: { VueEditor },
  mixins: [BaseView, KnowledgeBaseMixin],
  data() {
    return {
      perm: "kb",
      allowSlugGeneration: false,
      categories: [],
      item: { name: "", slug: "", content: "", category_id: null },
      loading: false,
    };
  },
  computed: {
    kb() {
      for (const kb of this.$store.state.knowledgeBases) {
        if (kb.id === this.$route.params.kb) {
          return kb;
        }
      }
      return {};
    },
    url() {
      let slug = "";

      // add category slug
      if (this.item.category_id) {
        const category = this.categories.find((c) => c.id === this.item.category_id);
        if (category) {
          slug = "/" + category.slug;
        }
      }

      return slug + "/" + this.item.slug;
    },
  },
  async created() {
    this.setTitle(this.$t("kb.page"));
    this.checkPermission();

    this.categories = await this.loadCategories(this.$route.params.kb);
    await this.loadPage();
  },
  methods: {
    generateSlug() {
      if (!this.allowSlugGeneration) return;

      const ru = {
        а: "a",
        б: "b",
        в: "v",
        г: "g",
        д: "d",
        е: "e",
        ё: "e",
        ж: "j",
        з: "z",
        и: "i",
        к: "k",
        л: "l",
        м: "m",
        н: "n",
        о: "o",
        п: "p",
        р: "r",
        с: "s",
        т: "t",
        у: "u",
        ф: "f",
        х: "h",
        ц: "c",
        ч: "ch",
        ш: "sh",
        щ: "shch",
        ы: "y",
        э: "e",
        ю: "u",
        я: "ya",
        ъ: "ie",
        ь: "",
        й: "i",
        " ": "-",
      };
      this.item.slug = [...this.item.name.toLowerCase()]
        .map((l) => {
          let latL = ru[l.toLocaleLowerCase()];

          if (l !== l.toLocaleLowerCase()) {
            latL = latL.charAt(0).toLocaleUpperCase() + latL.slice(1);
          } else if (latL === undefined) {
            latL = l;
          }

          return latL;
        })
        .join("");
    },
    async loadPage() {
      if (this.$route.params.id && this.$route.params.id !== "new") {
        this.loading = true;
        try {
          const resp = await this.$api.get("/kb/" + this.$route.params.kb + "/pages/" + this.$route.params.id);
          this.item = resp.data;
          this.setTitle(this.$t("kb.page") + " " + this.item.name);
        } catch (error) {
          console.error("Error load page: ", error);
        }
        this.loading = false;
      } else {
        this.allowSlugGeneration = true;
      }
    },
    async save() {
      const item = { ...this.item };
      item.knowledge_base = this.kb.id;

      const resp = await this.$api.save("/kb/" + this.$route.params.kb + "/pages", item);
      if (resp.success) {
        this.showSnackbar("success", this.$t("saved"));
        this.$router.push({ name: "kb-pages", params: { id: this.kb.id } });
      } else {
        this.showSnackbar("error", this.$t("save-error"));
      }
    },
  },
};
</script>
