<script>
export default {
  name: "TicketsMixin",
  data() {
    return {
      data: { results: [], page: 1 },
    };
  },
  methods: {
    exportData() {
      const data = [],
        name = this.$t("name"),
        number = this.$t("tickets.number"),
        date = this.$t("date"),
        type = this.$t("type"),
        status = this.$t("status"),
        cashShift = this.$t("cash-shift"),
        amount = this.$t("amount"),
        account = this.$t("account");

      for (const item of this.data.results) {
        const row = {};
        row[number] = item.number;
        row[date] = item.created_at;
        row[type] = item.type.name;
        row[amount] = item.amount;
        row[name] = item.name;
        row[status] = item.status_name;
        row[cashShift] = item.session ? item.session.number : "";
        row[account] = item.account.name;
        data.push(row);
      }

      this.downloadCSV("tickets.csv", this.getCSVFromArray(data));
    },
    addTicket(board) {
      console.log(board);
      this.$store.commit("setTicket", { board });
    },
    showTicket(ticket) {
      this.$store.commit("setTicket", ticket);
    },
    async refresh() {
      const params = {
        client: this.filter.client,
        status: this.filter.status,
        user: this.filter.user,
        page: this.data.page,
        ordering: this.ordering_desc ? "-" + this.ordering : this.ordering,
      };

      try {
        this.loading = true;
        const response = await this.$api.get("tickets", params);
        this.loading = false;
        if (response.success) {
          this.data = response.data;
        }
      } catch (err) {
        console.error("Error getting tickets list", err);
      }
    },
  },
};
</script>
