<template>
  <div>
    <v-data-table
      :headers="headers"
      :loading="loading"
      :items="data.results"
      :server-items-length="data.count"
      :items-per-page="data.per_page"
      :mobile-breakpoint="0"
      hide-default-footer
      class="elevation-1 mt-2"
    >
      <template v-slot:item.type="{ item }">
        <v-icon :color="item.status === 'a' ? 'blue' : 'red'">mdi-phone-incoming-outline</v-icon>
      </template>
      <template v-slot:item.number="{ item }">
        {{ item.number }}
      </template>
      <template v-slot:item.client="{ item }">
        <router-link :to="{ name: 'client-view', params: { id: item.client.id } }" v-if="item.client">{{
          item.client.name
        }}</router-link>
      </template>
      <template v-slot:item.duration="{ item }">
        {{ item.duration }}
      </template>
      <template v-slot:item.employee="{ item }">
        <router-link :to="{ name: 'employee-view', params: { id: item.employee.id } }" v-if="item.employee">{{
          item.employee.name
        }}</router-link>
      </template>
      <template v-slot:item.status="{ item }">
        {{ $t("calls.status-" + item.status) }}
      </template>
      <template v-slot:item.listen="{ item }">
        <v-btn icon small :to="{ name: 'route-edit', params: { id: item.id } }" v-if="item.status === 'a'"
          ><v-icon small color="blue">mdi-play-circle-outline</v-icon></v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn small :to="{ name: 'route-edit', params: { id: item.id } }" color="primary" v-if="canEdit"
          ><v-icon small>mdi-pencil</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="text-center pt-2" v-if="data.pages > 1">
      <v-pagination v-model="data.page" :length="data.pages" @input="updatePage" />
    </div>
  </div>
</template>

<script>
import BaseView from "@/components/BaseView";

export default {
  name: "CallsTable",
  mixins: [BaseView],
  computed: {
    headers() {
      const headers = [
        { text: "", value: "type" },
        { text: this.$t("calls.number"), value: "number" },
        { text: this.$t("calls.client"), value: "client", sortable: false, align: "center" },
        { text: this.$t("calls.status"), value: "status", sortable: false, align: "center" },
        { text: this.$t("account.user"), value: "employee", sortable: false, align: "center" },
        { text: this.$t("calls.duration"), value: "duration", sortable: false, align: "center" },
        { text: this.$t("calls.listen"), value: "listen", sortable: false, align: "center" },
      ];
      if (this.canEdit) {
        headers.push({ text: "", value: "actions", align: "end", width: 58, sortable: false });
      }

      return headers;
    },
  },
  async created() {
    //await this.refresh()
  },
  data() {
    return {
      data: {
        pages: 1,
        results: [
          {
            number: "380631201112",
            client: { id: 1, name: "Test client" },
            status: "a",
            employee: { id: 1, name: "Alla Petrova" },
            duration: "2m 13s",
          },
          {
            number: "48555334223",
            client: { id: 1, name: "Andrew Smith" },
            status: "m",
            employee: { id: 1, name: "Alla Petrova" },
            duration: "0s",
          },
        ],
      },
      page: 1,
      loading: false,
    };
  },
  methods: {
    async refresh() {
      const params = { ...this.searchParams };
      params.page = this.page;

      this.loading = true;
      try {
        const resp = await this.$api.get("/routes", params);
        this.data = resp.data;
      } catch (error) {
        console.error("Error load routes list: ", error);
      }
      this.loading = false;
    },
    async updatePage(page) {
      this.page = page;
      await this.refresh();
    },
  },
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    searchParams: Object,
  },
  watch: {
    async searchParams() {
      await this.refresh();
    },
  },
};
</script>
