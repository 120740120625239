<template>
  <v-card>
    <v-toolbar color="primary" dark flat dense>
      <v-toolbar-title>
        <v-chip small :color="getTicketColor(ticket)">{{ ticket.status.name }}</v-chip>
        {{ $t("tickets.ticket") }} {{ ticket.title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-2">
      <h2 v-show="!editTitle" class="black--text" @click="editTitle = true">{{ ticket.title }}</h2>
      <v-text-field v-show="editTitle" v-model="ticket.title" outlined dense hide-details @blur="saveTitle" />
      <v-row class="mt-1">
        <v-col cols="12" sm="4"> {{ $t("date") }}: {{ showDateTime(ticket.created_at, true) }} </v-col>
        <v-col cols="12" sm="4">
          {{ $t("clients.client") }}: <span v-if="ticket.client">{{ ticket.client.name }}</span>
          <v-btn x-small icon class="ml-1" @click="clientSearchDialog = true"><v-icon>mdi-magnify</v-icon></v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          {{ $t("account.user") }}: <span v-if="ticket.user">{{ ticket.user.name }}</span>
        </v-col>
      </v-row>
      <hr />
      <v-list dense>
        <v-list-item-group>
          <template v-for="(msg, i) in ticket.messages">
            <v-list-item :key="i" class="my-1 px-1">
              <v-list-item-content>
                <v-list-item-title>{{ msg.name }}</v-list-item-title>
                <v-list-item-subtitle>{{ msg.text }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>{{ showDateTime(msg.created_at) }}</v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="'d' + i" />
          </template>
        </v-list-item-group>
      </v-list>
      <v-textarea
        v-model="message"
        outlined
        dense
        hide-details
        :placeholder="$t('write-new-message')"
        @keyup.enter="sendMessage"
      />
    </v-card-text>
    <v-dialog v-model="clientSearchDialog" width="600" :fullscreen="$vuetify.breakpoint.mobile">
      <ClientsSearchDialog @close="closeClientDialog" />
    </v-dialog>
  </v-card>
</template>

<script>
import BaseView from "@/components/BaseView";
import ClientsSearchDialog from "@/components/clients/SearchClientDialog.vue";

export default {
  name: "TicketView",
  components: { ClientsSearchDialog },
  mixins: [BaseView],
  props: {
    id: String,
    board: Object,
  },
  data() {
    return {
      clientSearchDialog: false,
      editTitle: false,
      message: "",
      ticket: {},
    };
  },
  async created() {
    await this.load();
  },
  methods: {
    async closeClientDialog(client) {
      this.clientSearchDialog = false;

      if (client) {
        await this.$api.save("tickets", { id: this.ticket.id, client_id: client.id });
        await this.load();
      }
    },
    async load() {
      try {
        const resp = await this.$api.get("tickets/" + this.id);
        if (resp.success) {
          this.ticket = resp.data;
          // this.ticket.name = msg.user_id ? msg.user.name : ticket.client ? ticket.client.name : ''
          this.ticketDialog = true;
        }
      } catch (err) {
        this.showSnackbar("error", "Error load ticket details: " + this.id);
        console.error("Error load tickets details", err);
      }
    },
    async saveTitle() {
      try {
        const resp = await this.$api.patch("tickets/" + this.id, { title: this.ticket.title });
        if (resp.success) {
          await this.load();
        }
        this.editTitle = false;
      } catch (err) {
        this.showSnackbar("error", "Error to save new ticket title");
        console.error("Error to save title", err);
      }
    },
    async sendMessage() {
      const data = { text: this.message, user_id: this.$store.state.user.id };
      try {
        const resp = await this.$api.post("tickets/" + this.id + "/messages", data);
        if (resp.success) {
          this.message = "";
          await this.loadItem();
        }
      } catch (err) {
        this.showSnackbar("error", "Error to add ticket comment");
        console.error("Error to send message", err);
      }
    },
  },
};
</script>
