import Axios from "axios";

const tokenField = "supportix_token";

class API {
  constructor() {
    this.api = Axios.create({
      baseURL: "/api/",
      crossDomain: true,
      mode: "cors",
      timeout: 1000,
      headers: { "Content-Type": "application/json" },
    });

    const that = this;
    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error.response.status === 401) {
          that.$router.replace({ name: "login" });
        }
        return Promise.reject(error.response);
      }
    );

    // load saved token
    const token = this.getToken();
    if (token) {
      this.setToken(token);
    }
  }

  buildParams(params) {
    const searchParams = new URLSearchParams();

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null) return;

        if (Array.isArray(value)) {
          value.forEach((value) => searchParams.append(key, value.toString()));
        } else {
          searchParams.append(key, value.toString());
        }
      });
    }

    return params ? "?" + searchParams.toString() : "";
  }

  async delete(url) {
    const resp = await this.api.delete(url);
    resp.success = resp.status === 204;
    return resp;
  }

  async get(url, params) {
    const resp = await this.api.get(url + this.buildParams(params));
    resp.success = resp.status >= 200 && resp.status < 300;
    return resp;
  }

  async patch(url, data) {
    const resp = await this.api.patch(url, data);
    resp.success = resp.status >= 200 && resp.status < 300;
    return resp;
  }

  async post(url, data) {
    const resp = await this.api.post(url, data);
    resp.success = resp.status >= 200 && resp.status < 300;
    return resp;
  }

  async put(url, data) {
    const resp = await this.api.put(url, data);
    resp.success = resp.status >= 200 && resp.status < 300;
    return resp;
  }

  async save(url, obj) {
    try {
      let resp;
      if (obj.id) {
        resp = await this.put(url + "/" + obj.id, obj);
      } else {
        resp = await this.post(url, obj);
      }
      return resp;
    } catch (e) {
      console.error("Error save", e);
      return { success: false, error: e };
    }
  }

  saveToken(token) {
    if (token) {
      localStorage.setItem(tokenField, token);
    } else {
      localStorage.removeItem(tokenField);
    }
    this.setToken(token);
  }

  setToken(token) {
    if (token) {
      this.api.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
      delete this.api.defaults.headers.common["Authorization"];
    }
  }

  getToken() {
    return localStorage.getItem(tokenField);
  }
}

export default {
  install(Vue) {
    Vue.prototype.$api = new API();
  },
};
