<template>
  <v-container fluid>
    <v-card>
      <v-card-title>
        {{ $t("chats.templates") }}
        <v-spacer />
        <v-btn small color="primary" @click="add">{{ $t("chats.add-template") }}</v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table :headers="headers" :items="items" class="elevation-1">
          <template #item.type="{ item }">
            {{ typeNames[item.type] | mdash }}
          </template>
          <template #item.text="{ item }">
            {{ getText(item) }}
          </template>
          <template #item.actions="{ item }">
            <v-btn small color="info" @click="edit(item)"><v-icon small>mdi-pencil</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="editDialog" persistent max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark dense flat>
          <v-toolbar-title>{{ $t("chats.template") }} {{ item.name }}</v-toolbar-title>
          <v-spacer />
          <v-btn icon dark @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-3">
          <v-row>
            <v-col cols="8">
              <v-text-field v-model="item.name" dense outlined hide-details :label="$t('name')" />
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="item.type"
                dense
                outlined
                hide-details
                :label="$t('type')"
                :items="types"
                item-value="id"
                item-text="name"
              />
            </v-col>
            <v-col v-for="lang in item.langs" :key="lang.code" cols="12">
              <v-textarea v-model="lang.content" dense outlined hide-details auto-grow rows="3" :label="lang.code" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" small @click="deleteItem">{{ $t("delete") }}</v-btn>
          <v-spacer />
          <v-btn color="success" small @click="save">{{ $t("save") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import BaseView from "@/components/BaseView.vue";

const baseURL = "/chat/templates";

export default {
  name: "SettingsChannelTemplates",
  mixins: [BaseView],
  data() {
    return {
      perm: "channels",
      editDialog: false,
      headers: [
        { text: this.$t("name"), value: "name", sortable: false },
        { text: this.$t("type"), value: "type", sortable: false },
        { text: this.$t("message"), value: "text", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      items: [],
      item: { langs: [], type: "txt", name: "" },
      types: [
        { id: "t", name: this.$t("chat.text") },
        { id: "h", name: this.$t("chat.html") },
      ],
    };
  },
  computed: {
    langs() {
      return this.$store.state.account.langs || [];
    },
    typeNames() {
      return this.arrayToMap(this.types);
    },
  },
  async created() {
    this.setTitle(this.$t("chats.templates"));
    this.checkPermission();
    await this.refresh();
  },
  methods: {
    add() {
      this.item = { languages: [], type: "txt", name: "" };
      for (const lang of this.langs) {
        this.item.langs.push({ name: lang, content: "" });
      }
      this.editDialog = true;
    },
    getText(item, lang = "ru") {
      if (item.langs) {
        for (const translate of item.langs) {
          if (translate.code === lang) return translate.content;
        }
      }
      return "";
    },
    async deleteItem(id) {
      const itemId = id || this.item.id;
      await this.$api.delete(baseURL + "/" + itemId);

      this.editDialog = false;
      await this.refresh();
    },
    edit(item) {
      this.item = item;
      this.editDialog = true;
    },
    async closeEditing() {
      this.editDialog = false;
      this.item = { languages: [], type: "txt", name: "" };
      this.showSnackbar("success", this.$t("saved"));

      await this.refresh();
    },
    async save() {
      let item = { ...this.item };

      const langs = {};
      for (const lang of item.langs) {
        langs[lang.code] = lang.content || "";
      }
      item.langs = langs;

      if (item.id) {
        try {
          let url = baseURL + "/" + item.id;
          const resp = await this.$api.patch(url, item);
          if (resp.success) {
            await this.closeEditing();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          const resp = await this.$api.post(baseURL, item);
          if (resp.success) {
            await this.closeEditing();
            await this.refresh();
          }
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
    async refresh() {
      try {
        const resp = await this.$api.get(baseURL);
        if (resp.success) {
          this.$store.commit("setTemplates", resp.data.templates);
          this.items = resp.data.templates.map((t) => {
            return {
              id: t.id,
              uid: t.uid,
              name: t.name,
              type: t.type,
              langs: Object.keys(t.langs).map((lang) => {
                return { code: lang, content: t.langs[lang] };
              }),
            };
          });
        }
      } catch (err) {
        console.error("Error load chat templates: ", err);
      }
    },
  },
};
</script>
