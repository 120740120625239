import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCopyVwYrcrkm6sF-3zDaderL1yfckE5Os",
  authDomain: "supportix.firebaseapp.com",
  projectId: "supportix",
  storageBucket: "supportix.appspot.com",
  messagingSenderId: "517547823438",
  appId: "1:517547823438:web:7579f11baac3edfcd20e1f",
  measurementId: "G-DR4WVJL7S9",
};

const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

if (isSupported()) {
  onMessage(messaging, (payload) => {
    console.log("[Supportix] Receiving foreground message", payload);

    const notification = new Notification(payload.notification.title, {
      body: payload.notification.body,
      icon: "/favicon.ico",
      tag: payload.data.chat,
    });
    notification.onclick = function () {
      notification.close();
      if (payload.data.account_url) {
        window.location.href = payload.data.account_url + "/chats/" + payload.data.chat;
      }
    };
  });
}

export const getPushToken = async () => {
  try {
    return await getToken(messaging, {
      vapidKey: "BPg4VJVXgfXKtv9e9bPz3eojTnThbtl7U62uryB8sSTZ31BQIHfoUowisl-BvAL9X_bfZ4cQ0KdU2qjpO4aB8JQ",
    });
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};
