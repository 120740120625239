<script>
export default {
  name: "ChatMixin",
  filters: {
    initials(name) {
      if (!name) return "";
      if (name.indexOf(" ") > -1) {
        let names = name.split(" ");
        name = names[0].substring(0, 1).toUpperCase() + names[1].substring(0, 1).toUpperCase();
      } else {
        name = name.substring(0, 2).toUpperCase();
      }
      return name;
    },
  },
  methods: {
    getAvatar(thread) {
      //if (thread.avatar) return thread.avatar;
      if (!thread.type) return null;
      // return require(`@/assets/icons/${thread.type}.svg`);
      return `https://cdn.supportix.net/icons/${thread.type}.svg`;
    },
    showDateTime(dt) {
      if (!dt) return "";
      const dtm = this.$moment(dt),
        d = new Date();

      let format = "HH:mm";
      if (!dtm.isSame(d, "day")) {
        let dateFormat = "DD MMM";
        if (!dtm.isSame(d, "year")) {
          dateFormat = "DD MMM YY";
        }
        format = dateFormat + ", " + format;
      }
      return dtm.format(format);
    },
    async setUser(userId) {
      await this.updateThread(this.thread, { user_id: userId });
    },
    async updateThread(thread, data) {
      try {
        const resp = await this.$api.patch("chat/threads/" + thread.id, data);
        this.$store.commit("updateThread", resp.data);
        return resp.data;
      } catch (err) {
        console.error("Can not update thread", err);
        return null;
      }
    },
    updateThreadText(thread, text) {
      this.$store.commit("updateThread", { ...thread, text });
    },
  },
};
</script>
