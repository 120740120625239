<script>
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function roundFloat(value, count) {
  if (isNaN(value)) return 0;
  let num = parseFloat(value);
  if (num !== parseInt(num)) num = num.toFixed(count);
  return num;
}

export default {
  data() {
    return {
      languages: [
        { id: "en", name: "English", flag: require("@/assets/flags/gb.svg") },
        //{id: 'es', name: 'Español', flag: require('@/assets/flags/es.svg')},
        //{id: 'pt', name: 'Português', flag: require('@/assets/flags/pt.svg')},
        //{id: 'it', name: 'Italiano', flag: require('@/assets/flags/it.svg')},
        //{id: 'de', name: 'Deutsch', flag: require('@/assets/flags/de.svg')},
        //{id: 'pl', name: 'Polski', flag: require('@/assets/flags/pl.svg')},
        //{id: 'tr', name: 'Türkçe', flag: require('@/assets/flags/tr.svg')},
        //{ id: "ru", name: "Русский", flag: require("@/assets/flags/ru.svg") },
        { id: "uk", name: "Українська", flag: require("@/assets/flags/ua.svg") },
      ],
    };
  },
  filters: {
    floatformat(n) {
      var num = parseFloat(n);
      if (num !== parseInt(num)) num = num.toFixed(2);
      return num;
    },
    yesno(value) {
      return value ? "yes" : "no";
    },
    mdash(str) {
      return !!str > 0 ? str : "-";
    },
    nl2br(str) {
      const breakTag = "<br />";
      return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
    },
    round(n) {
      return roundFloat(n, 2);
    },
    round_3(n) {
      return roundFloat(n, 3);
    },
  },
  methods: {
    add() {
      this.edit({});
    },
    arrayToMap(arr) {
      const map = {};
      for (const item of arr) {
        map[item.id] = item.name;
      }
      return map;
    },
    checkPermission() {
      if (!this.$store.state.user.perms) {
        return false;
      }
      if (this.perm && this.$store.state.user.perms && !(this.perm in this.$store.state.user.perms)) {
        console.warn("Access denied: " + this.perm);
        //this.$router.replace({name: 'login'})
      }
    },
    getChannelIcon(type) {
      return require("@/assets/icons/" + type + ".svg");
    },
    getTicketColor() {
      return "red";
    },
    processAPIErrors(data) {
      const errors = {};
      for (const err of data.detail) {
        errors[err.loc[1]] = this.$t("errors." + err.msg.replace(" ", "_"));
      }
      return errors;
    },
    setTitle(title, title2) {
      if (title2) {
        title = title + " – " + title2;
      }
      let account = "";
      if (this.$store.state.user.account_name) {
        account = " – " + this.$store.state.user.account_name;
      }
      document.title = title + account + " – Supportix";
    },
    showDateTime(d, showDate = false) {
      const dm = this.$moment(d);
      return dm.format(dm.isSame(new Date(), "day") || showDate ? "HH:mm" : "DD MMM, HH:mm");
    },
    showSnackbar(color, text) {
      this.$store.commit("setSnackbar", {
        color: color,
        text: text,
        show: true,
        timeout: 4000,
      });
    },
    requiredValidator(v) {
      return (v !== "" && v !== null && v !== undefined && v.toString().trim() !== "") || this.$t("required-field");
    },
    emailValidator(email) {
      return emailRegExp.test(email) || this.$t("email-invalid");
    },
    digitsValidator(v) {
      return (v && !!Number(v)) || "Only digits allowed";
    },
    updatePage(page) {
      this.data.page = page;
      this.refresh();
    },
  },
};
</script>
