<template>
  <v-container fluid>
    <TicketsColumns v-if="board.type === 'c'" :board="board" />
    <TicketsList v-if="board.type === 'l'" :board="board" />
  </v-container>
</template>

<script>
import TicketsList from "./TicketsList";
import TicketsColumns from "./TicketsColumns";
import TicketsMixin from "./TicketsMixin";

export default {
  name: "TicketsBoard",
  mixins: [TicketsMixin],
  components: { TicketsColumns, TicketsList },
  computed: {
    board() {
      return this.$store.getters.getBoard(this.$route.params.id) || {};
    },
  },
};
</script>
