<template>
  <v-card>
    <v-toolbar color="primary" dark dense flat>
      <v-toolbar-title>{{ $t("widgets.widget") }} {{ item.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-3">
      <v-row>
        <v-col><v-text-field v-model="item.name" dense outlined hide-details /></v-col>
        <v-col
          ><v-select
            v-model="item.channels"
            multiple
            dense
            outlined
            hide-details
            :items="channels"
            item-value="id"
            item-text="name"
        /></v-col>
        <v-col
          ><v-select
            v-model="item.actions"
            multiple
            dense
            outlined
            hide-details
            :items="actions"
            item-value="id"
            item-text="name"
        /></v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-for="(cond, i) in item.conditions" :key="i">
            <div v-if="cond.id === 'chats.phrase'"><v-text-field v-model="phrase" dense outlined hide-details /></div>
            <div v-if="cond.id === 'chats.nonWorkingTime'">
              <div v-for="day in workingTime" :key="day.id" class="d-flex">
                <strong>{{ day.name }}</strong>
                <v-time-picker v-model="day.time[0]" dense outlined />
                <v-time-picker v-model="day.time[1]" dense outlined />
              </div>
            </div>
          </div>
          <v-btn color="info" x-small @click="addCondition">add</v-btn>
        </v-col>
        <v-col> </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="success" @click="save">{{ $t("save") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
const baseUrl = "triggers/";

export default {
  name: "TriggerEdit",
  props: {
    channels: {
      type: Array,
      default: () => [],
    },
    trigger: Object,
  },
  data() {
    return {
      actions: [{ id: "chats.setThreadGroup", name: "Test" }],
      conditions: [
        { id: "chats.noThreadGroup", name: "Не выбрана группа" },
        { id: "chats.noThreadEmail", name: "Нет Email" },
        { id: "chats.onStart", name: "Начало диалога" },
        { id: "chats.nonWorkingTime", name: "Рабочее время" },
        { id: "chats.phrase", name: "Поиск фразы" },
      ],
      item: { conditions: [] },
      phrase: "",
      workingTime: [
        { id: "mon", time: ["9:00", "19:00"] },
        { id: "tue", time: ["9:00", "19:00"] },
        { id: "wed", time: ["9:00", "19:00"] },
        { id: "thu", time: ["9:00", "19:00"] },
        { id: "fri", time: ["9:00", "19:00"] },
        { id: "sun", time: ["", ""] },
        { id: "sat", time: ["", ""] },
      ],
    };
  },
  watch: {
    trigger(trigger) {
      this.item = trigger;
    },
  },
  methods: {
    addCondition() {
      this.item.conditions.push({});
    },
    async save() {
      const item = { ...this.item };

      if (item.id) {
        try {
          await this.$api.patch(baseUrl + "/" + item.id, item);
          await this.closeEditing();
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      } else {
        try {
          await this.$api.post(baseUrl, item);
          await this.closeEditing();
        } catch (error) {
          this.showSnackbar("error", this.$t("save-error"));
          this.errors = error.data;
        }
      }
    },
  },
};
</script>
