<template>
  <v-card>
    <v-toolbar color="primary" dark dense flat>
      <v-toolbar-title>{{ $t("clients.search") }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close', null)">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pa-4">
      <v-text-field
        v-model="search"
        outlined
        dense
        hide-details
        class="mb-3"
        :label="$t('clients.search-field')"
        :placeholder="$t('clients.clients.search-field')"
      />
      <v-data-table
        dense
        :headers="headers"
        :loading="loading"
        :items="clients"
        v-show="!showClientForm"
        :mobile-breakpoint="0"
        hide-default-footer
        class="clients elevation-1 mb-2"
      >
        <template #[`item.name`]="{ item }">
          <div @click="setClient(item)" class="cursor-pointer">{{ item.name }}</div>
        </template>
        <template #[`item.contacts`]="{ item }">
          <v-tooltip v-if="item.phone" bottom>
            <template #activator="{ on }">
              <v-icon color="blue" class="mx-1" v-on="on">mdi-phone</v-icon>
            </template>
            <span>{{ item.phone }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.email" bottom>
            <template #activator="{ on }">
              <v-icon color="orange" class="mx-1" v-on="on">mdi-email</v-icon>
            </template>
            <span>{{ item.email }}</span>
          </v-tooltip>
        </template>
        <template #[`item.actions`]="{ item }">
          <v-btn small color="primary" @click="setClient(item)"><v-icon small>mdi-check</v-icon></v-btn>
        </template>
      </v-data-table>
      <div v-show="showAddClient" class="text-center">
        <v-btn small outlined color="info" @click="showClientForm = true">{{ $t("clients.add") }}</v-btn>
      </div>
      <ClientForm v-if="showClientForm" class="mt-3" :first_name="search" :toolbar="false" @close="closeClientForm" />
    </v-card-text>
  </v-card>
</template>

<script>
import ClientForm from "@/components/clients/ClientForm";

export default {
  name: "SearchClientDialog",
  components: { ClientForm },
  created() {
    if (this.client) {
      this.clients.push(this.client);
    }
  },
  data() {
    return {
      showClientForm: false,
      loading: false,
      headers: [
        { text: this.$t("clients.full-name"), value: "name" },
        { text: this.$t("clients.contacts"), value: "contacts", align: "center", sortable: false },
        { text: this.$t("clients.company"), value: "company", sortable: false },
        { text: this.$t("clients.comment"), value: "comment", sortable: false },
        { text: "", value: "actions", align: "end", width: 58, sortable: false },
      ],
      clients: [],
      search: "",
    };
  },
  computed: {
    showAddClient() {
      return this.search.length > 2 && this.clients.length === 0;
    },
  },
  methods: {
    async closeClientForm() {
      this.showClientForm = false;
      await this.searchClients();
    },
    async searchClients(params) {
      this.loading = true;
      try {
        const resp = await this.$api.get("clients", params);
        if (resp.success) {
          this.clients = resp.data.clients;
        }
      } catch (err) {
        console.error("Error search clients:", err);
      }
      this.loading = false;
    },
    setClient(client) {
      this.$emit("client", client);
    },
  },
  props: {
    client: Object,
  },
  watch: {
    client() {
      if (this.client) {
        this.clients.push(this.client);
      }
    },
    async search() {
      if (this.search.length < 2) return;
      await this.searchClients({ search: this.search });
    },
  },
};
</script>

<style scoped lang="css">
.v-card__text {
  min-height: 300px;
}
</style>
