<script>
export default {
  name: "KnowledgeBaseMixin",
  methods: {
    async loadCategories(knowledgeBaseId) {
      try {
        const resp = await this.$api.get("/kb/" + knowledgeBaseId + "/categories");
        return resp.data.categories.map((c) => {
          c.category = true;
          c.children = [];
          return c;
        });
      } catch (e) {
        console.error("Error load KB categories", e);
      }
      return [];
    },
  },
};
</script>
