<template>
  <v-card>
    <v-toolbar color="primary" dark dense flat>
      <v-toolbar-title>{{ $t("tickets.add") }} {{ board.name }}</v-toolbar-title>
      <v-spacer />
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="pt-4">
      <v-text-field v-model="ticket.title" outlined dense hide-details :label="$t('tickets.title')" />
      <v-row class="my-2">
        <v-col cols="12" sm="6">
          {{ $t("clients.client") }}:
          <v-autocomplete
            v-model="ticket.client"
            :items="clients"
            item-text="name"
            item-value="id"
            class="mt-2"
            dense
            outlined
            hide-details
            :no-data-text="$t('not-found')"
            :search-input.sync="searchClient"
            return-object
          >
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="6">
          {{ $t("account.user") }}:
          <v-autocomplete
            v-model="ticket.user"
            :items="users"
            item-text="name"
            item-value="id"
            class="mt-2"
            dense
            outlined
            hide-details
            :no-data-text="$t('not-found')"
            return-object
          >
            <template #item="{ item }">
              <span class="body-2 mr-4">{{ item.name }}</span> <small>{{ item.position }}</small>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-textarea
        v-model="ticket.text"
        outlined
        dense
        hide-details
        :placeholder="$t('tickets.message')"
        @keyup.enter="save"
      />
    </v-card-text>
    <v-card-actions>
      <v-btn color="blue darken-1" text outlined @click="$emit('close')">{{ $t("close") }}</v-btn>
      <v-spacer />
      <v-btn color="success" @click="save">{{ $t("tickets.add") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "NewTicketForm",
  computed: {
    ...mapState(["users"]),
  },
  watch: {
    async searchClient(query) {
      if (!query || query.length < 2) return;

      try {
        const resp = await this.$api.get("clients?search=" + query);
        if (resp.success) {
          this.clients = resp.data.results;
        }
      } catch (err) {
        console.error("Error load clients", err);
      }
    },
  },
  created() {
    if (this.client) {
      this.ticket.client = this.client;
      this.ticket.client_id = this.client.id;
      this.clients.push(this.client);
    }
  },
  props: {
    board: Object,
    client: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      clients: [],
      searchClient: "",
      ticket: {
        title: "",
        text: "",
        client: {},
        client_id: null,
        user: {},
        user_id: null,
      },
    };
  },
  methods: {
    async save() {
      const data = { board_id: this.board.id, title: this.ticket.title, text: this.ticket.text };
      if (this.ticket.client.id) {
        data.client_id = this.ticket.client.id;
      }
      if (this.ticket.user.id) {
        data.user_id = this.ticket.user.id;
      }

      try {
        const resp = await this.$api.post("tickets/new", data);
        if (resp.success) {
          this.$emit("close");
        }
      } catch (err) {
        console.error("Error creating a new ticket", err);
      }
    },
  },
};
</script>
